import * as React from "react";
import { connect } from "react-redux";
import { MenuState } from "../menu/MenuTypes";
import { confirmMenu, showFoodInformationDialog, selectMenu } from "./OrderActions";
import { FoodItemComponent, FoodItemProps, FoodItemOwnProps, FoodItemDispatchProps } from "./FoodItemComponent";
import { FoodItem, FoodCourseGroup, Allergen, Nutrient, MenuType } from "./OrderTypes";

const getCategory = (foodItemID: number, courseGroups: { [key: number]: FoodCourseGroup }) => {
    for (const key in courseGroups) {
        if (courseGroups[key].foodItems.find(x => x === foodItemID)) {
            return courseGroups[key].title;
        }
    }
}
const mapStateToProps = (state: any, ownProps: FoodItemOwnProps): FoodItemProps => {

    let id = ownProps.id;
    let myMenuState = state.menu as MenuState; 
    let foodItem: FoodItem = myMenuState.menu.foodItems[id]; 

    if (!foodItem) {
        return {
            allergens: false,
            allergensList: [],
            nutrients: false,
            nutrientList: [],
            category: '',
            description: '',
            image_url: undefined,
            menuchartlinenr: 0,
            courseGroupId: 0,
            detailedDescription: ''
        };
    }
      
    return { 
        description: foodItem.description,
        menuchartlinenr: foodItem.menuChartLineNr,
        category: getCategory(ownProps.id, myMenuState.menu.courseGroups),
        allergens: foodItem.allergens,
        allergensList: foodItem.allergensList,
        nutrients: foodItem.nutrients,
        nutrientList: foodItem.nutrientsList,
        image_url: foodItem.image_url,
        courseGroupId: foodItem.courseGroupId,
        detailedDescription: foodItem.detailedDescription
    };
};

const mapDispatchToProps = (dispatch: any): FoodItemDispatchProps => {
    return {
        showFoodInformationDialog: (description: string, allergensList: Allergen[], nutrientList: Nutrient[], url_image: string, detailedDescription: string) => {
            dispatch(showFoodInformationDialog(description, allergensList, nutrientList, url_image, detailedDescription));
        },
        openInCustomMenu: (courseGroupId: number) => {
            dispatch(selectMenu(MenuType.CUSTOM_MENU, null, courseGroupId));
        }
    };
};

export const FoodItemContainer = connect(
    mapStateToProps, mapDispatchToProps)(FoodItemComponent) as React.ComponentClass<FoodItemOwnProps>;
