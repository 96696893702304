import * as React from "react";
import i18n from 'i18next';

export interface DateTimeLabelProps {
    value: any;
    type: DateTimeLabelFormat;
}

export class DateTimeLabel extends React.Component<DateTimeLabelProps, any>{

    format_two_digits(n: any) {
        return n < 10 ? "0" + n : n;
    }

    time_format(d: any) {
        var hours = this.format_two_digits(d.getHours());
        var minutes = this.format_two_digits(d.getMinutes());
        var seconds = this.format_two_digits(d.getSeconds());
        return hours + ":" + minutes;
    }

    public render() {
        const weekDay = [
            i18n.t('weekDay.Zondag'),
            i18n.t('weekDay.Maandag'),
            i18n.t('weekDay.Dinsdag'),
            i18n.t('weekDay.Woensdag'),
            i18n.t('weekDay.Donderdag'),
            i18n.t('weekDay.Vrijdag'),
            i18n.t('weekDay.Zaterdag'),
        ];

        const month = [
            i18n.t('month.januari'), i18n.t('month.februari'), i18n.t('month.maart'), i18n.t('month.april'), i18n.t('month.mei'), i18n.t('month.juni'), i18n.t('month.juli'), i18n.t('month.augustus'), i18n.t('month.september'), i18n.t('month.oktober'), i18n.t('month.november'), i18n.t('month.december'),

        ];

        let value = this.props.value;

        if (value == undefined) {
            return <span/>;
        }

        if (typeof (value) == "string") {
            value = new Date(value);
        }

        let valueFormat = "";

        switch (this.props.type) {
            case DateTimeLabelFormat.Date:
                valueFormat = value.getDate() + "-" + (value.getMonth() + 1) + "-" + value.getFullYear();
                break;

            case DateTimeLabelFormat.Time:
                valueFormat = this.time_format(new Date(value.getTime()));
                break;
            case DateTimeLabelFormat.FullDate:
                valueFormat = weekDay[value.getDay()] + " " + value.getDate() + " " + month[(value.getMonth())] + " " + value.getFullYear();
                break;
            case DateTimeLabelFormat.Day:
                valueFormat = weekDay[value.getDay()];
                break;
            default:
                valueFormat = value.getDate() +
                    "/" +
                    (value.getMonth() + 1) +
                    "/" +
                    value.getFullYear() +
                    " " +
                    this.time_format(value);
                break;
        }

        return <span>
                   <span>{valueFormat}</span>
               </span>;
    }
}

export enum DateTimeLabelFormat {
    Date,
    Time,
    DateTime,
    FullDate,
    Day
}
