import { Theme } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { withTheme, WithTheme as WithThemeProps } from '@material-ui/styles';
import * as React from "react";
import { ProfileAvatar } from "./ProfileAvatar";
import { Profile } from "./ProfileTypes";
import { withTranslation, WithTranslation } from "react-i18next";
import i18n from 'i18next';


export interface SelectProfileProps {
    patient: Profile,
    guests: Profile[],
    onSelectProfile: (profile: Profile) => void;
}


class SelectProfileComponent extends React.Component<SelectProfileProps & WithThemeProps<Theme> & WithTranslation, any> {
     
    private renderProfile(profile: Profile) {
        const secondaryText = profile.isPrimary ? this.props.t("profiel.Patiënt") : this.props.t("profiel.Gast");
        return <ListItem key={profile.id} button
            onClick={() => {
                i18n.changeLanguage(profile.preferredLanguageCulture);
                this.props.onSelectProfile(profile);
            }
            }>
            <ListItemAvatar>
                <ProfileAvatar profile={profile}/>
            </ListItemAvatar>
            <ListItemText primary={profile.name} secondary={secondaryText} />
        </ListItem>
    }

    public render() {

        return <div style={{ alignContent: 'center', paddingLeft: '20px' }}>
            <List component="nav">
                {this.renderProfile(this.props.patient)}
                {this.props.guests.map(guest => this.renderProfile(guest))}
            </List>
        </div>
    }
}

export default withTranslation()(withTheme<Theme, typeof SelectProfileComponent>(SelectProfileComponent));
