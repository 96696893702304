import { connect } from "react-redux";
import { ProfileState, Profile } from "./../profile/ProfileTypes";
import { setActiveProfile } from "./../profile/ProfileActions"; 
import { getMealDays } from "./../menu/MenuActions";
import { HomeComponent, HomeProps, HomeDispatchProps } from "./HomeComponent";
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state: any): HomeProps => {
    const profileState = state.profile as ProfileState;
    
    return {
        hasMultipleProfiles: profileState.profiles.length > 1,
        patient: profileState.patient,
        guests: profileState.guests
    }
}

const mapDispatchToProps = (dispatch: any): HomeDispatchProps => {
    return {
        selectProfile: (profile: Profile) => {
            dispatch(getMealDays(profile));
        },
    };
}

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeComponent)) as React.ComponentClass;