import * as React from "react";
import { connect } from "react-redux";
import { OrderState } from "./OrderTypes";
import { OrderProps, OrderComponentDispatchProps, OrderComponent } from "./OrderComponent";
import { selectMenu, clearOrder, closeFoodInformationDialog } from "./OrderActions";
import { MenuType } from "./OrderTypes";
import { ConfigState } from "../config/ConfigTypes";
import { MenuState } from "../menu/MenuTypes";

const mapStateToOrderProps = (state: any): OrderProps => {
    let myOrderState = state.order as OrderState;
    let myMenState = state.menu as MenuState;
    let myConfigState = state.config as ConfigState;
    let featureMealPictures = myConfigState.mealPictures;
    let featureIsDietOptionVisible = myConfigState.showDiet;
    if (myOrderState.mealMoment == null) {
        return {
            mealMoment: undefined,
            menuType: MenuType.DAILY_MENU,
            foodItemDialog: { show: false, allergens: null, nutrients: null, description: "", url_image: "", detailedDescription: "" },
            featureMealPictures: featureMealPictures,
            featureIsDietOptionVisible: featureIsDietOptionVisible,
            isDirty: myOrderState.isDirty,
            foodItems: null,
            foodItemsPortions: null,
            foodItemsExtra: null
        };
    }

    return {
        mealMoment: myOrderState.mealMoment,
        menuType: myOrderState.menuType,
        foodItemDialog: myOrderState.foodInformationDialog,
        featureMealPictures: featureMealPictures,
        featureIsDietOptionVisible: featureIsDietOptionVisible,
        isDirty: myOrderState.isDirty,
        foodItems: myMenState.menu.foodItems,
        foodItemsPortions: myOrderState.itemPortions,
        foodItemsExtra: myOrderState.foodItemsExtra
    };
};

const mapDispatchToProps = (dispatch: any): OrderComponentDispatchProps => {
    return {
        changeMenu: (event: any, tabIndex: any) => {
            let menuType = tabIndex as MenuType;
            dispatch(selectMenu(menuType));
        },
        unloadOrder: () => {
            dispatch(clearOrder());
        },
        closeDialog: () => {
            dispatch(closeFoodInformationDialog());
        },
    };
};

export const OrderContainer = connect(
    mapStateToOrderProps, mapDispatchToProps)(OrderComponent) as React.ComponentClass;
