import { IconButton, Link } from "@material-ui/core";
import * as React from "react";
import LogoCsam from "./logo-csam.png";
import { withTranslation, WithTranslation } from 'react-i18next';

export class CsamLoginComponent extends React.Component<WithTranslation> {     
    public render() {
        const { t } = this.props;
        return <React.Fragment>
            <Link href="/account/csam">
                <span>{t("csam.Aanmelden")}</span>
            </Link>
    
            <IconButton href="/account/csam">
                <img src={LogoCsam} style={{"maxWidth": "80%"}} />
                </IconButton>
            </React.Fragment>
    }
}
//export default withTranslation()(CsamLoginComponent);
export const CsamLoginContainer = withTranslation()(CsamLoginComponent);
