import { connect } from "react-redux";
import { ProfilePageProps } from "./ProfilePageComponent";
import ProfilePageComponent from "./ProfilePageComponent";
import { ProfileState, Profile } from "./ProfileTypes"; 

const mapStateToProps = (state: any): ProfilePageProps => {
    const profile = state.profile as ProfileState;
    const patient = profile.patient; 
    console.log('mapst');
    return {
        isDisclaimerTop: profile.isDisclaimerFirstTimeDisplayed,
        organizationName: patient.organizationName
    };
}

const mapDispatchToProps = (dispatch: any): any => {
    return {
    };
}

export const ProfilePageContainer = connect(mapStateToProps)(ProfilePageComponent) as React.ComponentClass;