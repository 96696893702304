import axios, { CancelTokenSource } from "axios";
import { push } from "connected-react-router";
import i18n from 'i18next';
import { forEachChild } from "typescript";
import { setOrderExtraFoodItems } from "../order/OrderActions";
import { MealDay, MealMoment, PatientMealMoments } from "../order/OrderTypes";
import { ApplicationState } from "./../../store";
import { getDiscriminationRules } from "./../order/discrimination/discriminationActions";
import { orderAction } from "./../order/OrderActions";
import { Profile } from "./../profile/ProfileTypes";
import { Menu } from './MenuTypes';
import { formatData } from "./Normalizr";

export const menuAction = {
    REQUEST_MENU: "REQUEST_MENU",
    SELECT_MEAL_DAY: "SELECT_MEAL_DAY",
    RECEIVE_DAILY_MENU: "RECEIVE_DAILY_MENU",
    RECEIVE_FOOD_ITEMS: "RECEIVE_FOOD_ITEMS",
    RECEIVE_COURSE_GROUPS: "RECEIVE_COURSE_GROUPS",
    RECEIVE_COURSES: "RECEIVE_COURSES",
    RECEIVE_MEAL_DAYS: "RECEIVE_MEAL_DAYS",
    RECEIVE_MEAL_MOMENT: "RECEIVE_MEAL_MOMENT",
    RECEIVE_MENU: "RECEIVE_MENU",
    CLEAR_MENU: "CLEAR_MENU",
    CONFIRM_NOMEALS: "CONFIRM_NOMEALS",
    SHOW_SNACKBAR: "SHOW_SNACKBAR",
    CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
    ERROR_RECEIVE_MENU: "ERROR_RECEIVE_MENU",
}

const getMenuCancellationToken = axios.CancelToken;
let cancellationTokenSource: CancelTokenSource;


export const getMenu = (mealMoment: MealMoment) => {
    return (dispatch: any, getState: any) => {
        var state = { ...getState() };
        
        if (cancellationTokenSource) {
            cancellationTokenSource.cancel();
        }   

        cancellationTokenSource = getMenuCancellationToken.source();

        //set menu.IsLoading
        dispatch(requestMenu());
        return axios({
            url: "/api/menu/menu",
            method: "get",
            params: {
                ID: mealMoment.id,
                date: mealMoment.date
            },
            cancelToken: cancellationTokenSource.token
        }).then(response => {
            for (const key in response.data.courses) {
                for (const cgkey in response.data.courses[key].courseGroups) {
                    let translatedtext = translateDescription(response.data.courses[key].courseGroups[cgkey].caption);
                    response.data.courses[key].courseGroups[cgkey].caption = translatedtext;
                }
            }
            mealMoment.description = translateDescription(mealMoment.description);
            dispatch(receiveMenu(mealMoment, response.data));
        }).catch(error => {
            dispatch(showSnackbar(i18n.t("menu.Mgeen"), "error", 15000));
            //dispatch(errorReceiveMenu(error));
        });
    };
}

export const getDailyMenu = (mealMoment: MealMoment) => {
    return (dispatch: any, getState: any) => {
        var state = { ...getState() };

        if (cancellationTokenSource) {
            cancellationTokenSource.cancel();
        }

        cancellationTokenSource = getMenuCancellationToken.source();

        //set menu.IsLoading
        dispatch(requestMenu());
        return axios({
            url: "/api/menu/dailymenu",
            method: "get",
            params: {
                ID: mealMoment.id,
                date: mealMoment.date
            },
            cancelToken: cancellationTokenSource.token
        }).then(response => {
            dispatch(receiveMenu(mealMoment, response.data)); //response.data));
        }).catch(error => {
            dispatch(showSnackbar(i18n.t("menu.Mgeen"), "error", 15000));
            //dispatch(errorReceiveMenu(error));
        });
    };
}

export const getMealDays = (profile: Profile) => {
    return (dispatch: any, getState: any) => {
        var state = { ...getState() } as ApplicationState;
        return axios({
            url: "/api/mealmoment/mealdays",
            method: "get",
            headers: { 'active_id': profile.id, 'active_culture': profile.preferredLanguageCulture }
        }).then((response) => {
            let mealmomentdata: PatientMealMoments = response.data;
            dispatch(receiveMealDays(mealmomentdata));
        }).catch((error) => {
            dispatch(showSnackbar(i18n.t("menu.Merror"), "error", 15000));
        });
    };
}

export const translateDescription = (description: string) => {
    if (description.indexOf("|tfrsc") < 0)
        return description;

    let found = true;
    while (found) {
        if (description.indexOf("|tfrsc|") >= 0) {
            let tfrsc = description.substr(description.indexOf("|tfrsc|"), 9).trim();
            tfrsc = isNumber(tfrsc.slice(-1)) ? tfrsc.substr(0, tfrsc.length - 1) : tfrsc;
            let tfrsc_translated = i18n.t("resource." + tfrsc.trim());
            description = description.replace(tfrsc, tfrsc_translated);
        }
        if (description.indexOf("|tfrscw|") >= 0) {
            let tfrscw = description.substr(description.indexOf("|tfrscw|"), 10).trim();
            tfrscw = isNumber(tfrscw.slice(-1)) ? tfrscw.substr(0, tfrscw.length - 1) : tfrscw;
            let tfrscw_translated = i18n.t("resource." + tfrscw.trim());
            description = description.replace(tfrscw, tfrscw_translated);
        }

        let position = description.indexOf("|tfrsc");
        if (position < 0)
            found = false
    }
    return description;
}

function isNumber(str: any) {
    return isNaN(str.slice(-1)) ? true : false;
}

export const selectMealDay = (index: number) => {
    return {
        type: menuAction.SELECT_MEAL_DAY,
        payload: index,
    };
}

export const confirmNoMeals = (mealDay: MealDay) => {
    return (dispatch: any, getState: any) => {
        const state = { ...getState() };
        const mealDate: string = mealDay.mealDate;
        return axios({
            url: "/api/menu/nomeals",
            timeout: 10000,
            method: "post",
            data: mealDay

        })
            .then(response => {
                dispatch({
                    type: menuAction.CONFIRM_NOMEALS,
                    payload: {
                        mealDate
                    }
                });

                dispatch(showSnackbar(i18n.t("menu.Mannuleer") + "!", "success", 10000));

            });

    }
}

export const receiveMenu = (mealMoment: MealMoment, data: any) => {
    return (dispatch: any) => {
         
        let menu: Menu = formatData(data);

        dispatch(getDiscriminationRules(mealMoment));
        dispatch({
            type: orderAction.SELECT_MEAL_MOMENT,
            payload: mealMoment
        });
        dispatch(push('order'));

        //dispatch(receiveFoodItems(data.entities.foodItems));
        //dispatch(receiveCourseGroups(data.entities.courseGroups));
        //dispatch(receiveCourses(data.entities.courses));
        dispatch(setOrderExtraFoodItems(data.extraFoodItems));
        dispatch({
            type: menuAction.RECEIVE_MENU,
            payload: menu,
        });
    };
}

export const errorReceiveMenu = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: menuAction.ERROR_RECEIVE_MENU,
        });
    };
}

export const requestMenu = () => {
    return {
        type: menuAction.REQUEST_MENU
    };
}
export const receiveDailyMenu = (data: any) => {
    return {
        type: menuAction.RECEIVE_DAILY_MENU,
        payload: data,
    };
}
export const receiveFoodItems = (data: any) => {
    return {
        type: menuAction.RECEIVE_FOOD_ITEMS,
        payload: data,
    };
}
export const receiveCourseGroups = (data: any) => {
    return {
        type: menuAction.RECEIVE_COURSE_GROUPS,
        payload: data,
    };
}

export const receiveCourses = (data: any) => {
    return {
        type: menuAction.RECEIVE_COURSES,
        payload: data,
    };
}

export const receiveMealDays = (data: PatientMealMoments) => {
    return {
        type: menuAction.RECEIVE_MEAL_DAYS,
        payload: data
    };
}

export const receiveMealMoment = (data: MealMoment) => {
    return {
        type: menuAction.RECEIVE_MEAL_MOMENT,
        payload: data
    };
}

export const clearMenu = () => {
    return {
        type: menuAction.CLEAR_MENU,
    };
}

export const showSnackbar = (text: string, variant: string, duration: number) => {
    return {
        type: menuAction.SHOW_SNACKBAR,
        payload: {
            text: text,
            variant: variant,
            duration: duration,
        },
    };
}

export const closeSnackbar = () => {
    return {
        type: menuAction.CLOSE_SNACKBAR,
    };
}