import * as React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import SuccessIcon from "@material-ui/icons/Done";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import styles from "./ErrorComponent.module.css";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { SnackbarStatus } from "../order/OrderTypes";
import green from '@material-ui/core/colors/green';
import { Theme } from "@material-ui/core/styles/createMuiTheme"; 
import { WithTheme as WithThemeProps, withTheme } from '@material-ui/styles';
import i18n from 'i18next';


export interface ErrorProps {
    snackbar: SnackbarStatus,
}

export interface ErrorSnackbarComponentDispatchProps {
    closeSnackbar: () => void;
}


class ErrorSnackbarComponent extends React.Component<ErrorProps & ErrorSnackbarComponentDispatchProps  & WithThemeProps<Theme>, any> {

    public render() {
        const errorColor = this.props.theme.palette.error.dark;
        const warningColor = this.props.theme.palette.error.light;
        
        const errorBackgroundColor = {
            backgroundColor: errorColor,
        };
        const warningBackgroundColor = {
            backgroundColor: warningColor,
        };
        const successBackgroundColor = {
            backgroundColor: green[400],
        };

        const errorIcon = () => {
            if (this.props.snackbar.variant === "error") {
                return <ErrorIcon className={styles.errorIcon} />
            }
        }

        const warningIcon = () => {
            if (this.props.snackbar.variant === "warning") {
                return <WarningIcon className={styles.warningIcon} />
            }
        }

        const successIcon = () => {
            if (this.props.snackbar.variant === "success") {
                return <SuccessIcon className={styles.succesIcon} />
            }
        }

        const snackBarMessages : string[] = this.props.snackbar.text.split("\n");
        
            return <Snackbar
                open={this.props.snackbar.show}
                onClose={this.props.closeSnackbar}
                autoHideDuration={this.props.snackbar.duration}
                resumeHideDuration={this.props.snackbar.duration * 2}>

                <SnackbarContent
                    style={this.props.snackbar.variant === "error" ? errorBackgroundColor : this.props.snackbar.variant === "warning" ? warningBackgroundColor : this.props.snackbar.variant === "success" ? successBackgroundColor : null}
                    aria-describedby="client-snackbar"
                    message={<span className={styles.snackbar}>
                        {
                            this.props.snackbar.variant === "success" ? successIcon() : this.props.snackbar.variant === "warning" ? warningIcon() : errorIcon()
                        }
                        <div> {
                            snackBarMessages.map((message, i) => {
                                return <div key={i}>{message.indexOf("|tfrsc") < 0 ? message : i18n.t("resource." + message)}</div>
                                    //return <div key={i}>{message}</div>
                                })}
                          </div>
                    </span>}
                    action={
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.props.closeSnackbar}
                        >
                            <CloseIcon />
                        </IconButton>
                    } />
            </Snackbar>
      
    }
}

export default withTheme<Theme, typeof ErrorSnackbarComponent>(ErrorSnackbarComponent);
