
import { discriminationAction } from './discriminationActions';
import { orderAction } from '../OrderActions';
import * as OrderTypes from '../OrderTypes';
import { ApplicationState } from '../../../store/index';
import { DiscriminationConfiguration, initialDiscriminationState } from './discriminationTypes';
import { DiscriminationSetExclusion, DiscriminationFoodItemSetExclusion } from './discriminationTypes';
import { DiscriminationRule, DiscriminationLock } from './discriminationTypes';
import { menuAction } from '../../menu/MenuActions';

export const discriminationReducer = (state: ApplicationState, action: any): ApplicationState => {
    switch (action.type) {
        case discriminationAction.RECEIVE_DISCRIMINATIONRULES:

            const configuration = action.payload as DiscriminationConfiguration;            

            
            //1 courseGroup can be in more sets
            const courseGroupSets: { [courseGroup: number]: number[] } = {};
            const foodItemDiscriminationSetMap: { [foodItemId: number]: number[] } = {};
            const foodItemSetDiscriminationMap: { [foodItemId: number]: number[] } = {};

            const foodItems = (state: ApplicationState): number[] => state.menu.menu.foodItemKeys;

            let portionCounter: { [ruleId: number]: number } = {};
            let typeCounter: { [ruleId: number]: number } = {};
            
            //initialize count 0 for all rules
            configuration.rulesList.forEach(ruleId => {
                portionCounter[ruleId] = 0;
                typeCounter[ruleId] = 0;
                configuration.rules[ruleId].components.forEach(compId => {
                    if (!courseGroupSets.hasOwnProperty(compId)) {
                        courseGroupSets[compId] = [];
                    }
                    courseGroupSets[compId].push(ruleId);                    
                });
            });


            for (let key in state.menu.menu.courseGroups) {
                let courseGroup = state.menu.menu.courseGroups[+key];
                courseGroupSets[courseGroup.id].forEach(setId => {                   
                    let courseGroupRule = configuration.rules[setId]; //courseGroup.discriminationSetNumber
                    if (courseGroupRule) {
                        courseGroup.foodItems.forEach(foodItemNr => {

                            if (!foodItemDiscriminationSetMap.hasOwnProperty(foodItemNr)) {
                                foodItemDiscriminationSetMap[foodItemNr] = [];
                            }
                            foodItemDiscriminationSetMap[foodItemNr].push(setId); //courseGroup.discriminationSetNumber
                            if (!courseGroupRule.foodItems) {
                                courseGroupRule.foodItems = [+foodItemNr];
                            } else {
                                courseGroupRule.foodItems.push(+foodItemNr);
                            }

                            let currentPortion: OrderTypes.ItemPortion = state.order.itemPortions[foodItemNr];
                            if (currentPortion && currentPortion.portion != 0) {
                                portionCounter[courseGroupRule.setNr] += currentPortion.portion;
                                typeCounter[courseGroupRule.setNr] += 1;
                            }
                        });

                    }
                });
            }

            for (let key in state.menu.menu.courseGroups) {
                let courseGroup = state.menu.menu.courseGroups[+key];
                let foodItemSetRules = configuration.exclusionsFoodItemSetList;
                courseGroup.foodItems.forEach(foodItemNr => {
                    let convertedFoodItem = foodItemNr - (100000000 * courseGroup.id);
                    for (let foodItemSetKey in foodItemSetRules) {
                        let exceptionSet = foodItemSetRules[+foodItemSetKey].exceptionChosenFoodItems;
                        if (courseGroup.nutritionNr == foodItemSetRules[+foodItemSetKey].nutritionId || foodItemSetRules[+foodItemSetKey].nutritionId == 0) {
                            for (let fi in exceptionSet) {
                                if (exceptionSet[+fi] == convertedFoodItem) {
                                    if (!foodItemSetDiscriminationMap.hasOwnProperty(foodItemNr)) {
                                        foodItemSetDiscriminationMap[foodItemNr] = [];
                                    }
                                    let forbiddenExclusionSet = foodItemSetRules[+foodItemSetKey].exceptionForbiddenFoodItems;
                                    for (let ffi in forbiddenExclusionSet) {
                                        let found = false;
                                        if (foodItemSetDiscriminationMap[foodItemNr].length == 0) {
                                            foodItemSetDiscriminationMap[foodItemNr].push(forbiddenExclusionSet[+ffi]);
                                        }
                                        else {
                                            foodItemSetDiscriminationMap[foodItemNr].forEach(fi => {
                                                if (fi == forbiddenExclusionSet[+ffi]) {
                                                    found = true;
                                                }
                                            })
                                            if (!found) {
                                                foodItemSetDiscriminationMap[foodItemNr].push(forbiddenExclusionSet[+ffi]);
                                            }
                                        }
                                    }
                                }
                            }
                        }                       
                    }
                });
            }

            for (let exclusionKey in configuration.exclusions) {
                let exclusion: DiscriminationSetExclusion = configuration.exclusions[exclusionKey];

                exclusion.discriminationSets.forEach(setNr => {
                    let rule: DiscriminationRule = configuration.rules[setNr];
                    if (!exclusion.foodItems) {
                        exclusion.foodItems = rule.foodItems;
                    } else {
                        exclusion.foodItems = exclusion.foodItems.concat(rule.foodItems);
                    }
                });
            }

            return {
                ...state,
                discrimination: {
                    configuration: configuration,
                    ruleByFoodItem: foodItemDiscriminationSetMap,
                    ruleByFoodItemSet: foodItemSetDiscriminationMap,
                    rulePortionCounter: portionCounter,
                    ruleTypeCounter: typeCounter,
                    courseGroupSets: courseGroupSets
                }
            };

        case orderAction.CHANGE_PORTION:

            const discrimination = state.discrimination;
            //disc. rules houden een lijst bij van totale porties per ruleset

            const foodItemNr: number = action.payload.id;
            const currentPortion: number = action.payload.currentPortion;
            const newPortion: number = action.payload.newPortion;
            const diff = newPortion - currentPortion;

            const rules = discrimination.ruleByFoodItem[foodItemNr]

            if (rules) {
                //rules available for foodItem
                let rulePortionCounters = { ...discrimination.rulePortionCounter };
                let ruleTypeCounters = { ...discrimination.ruleTypeCounter };
                rules.forEach(setNr => {
                    rulePortionCounters[setNr] += diff;
                    if (newPortion == 0 && currentPortion > 0) {
                        ruleTypeCounters[setNr] -= 1;
                    }
                    if (newPortion > 0 && currentPortion == 0) {
                        ruleTypeCounters[setNr] += 1;
                    }
                });
                
                return {
                    ...state,
                    discrimination: {
                        ...discrimination,
                        rulePortionCounter: rulePortionCounters,
                        ruleTypeCounter: ruleTypeCounters
                    }
                };

            }
            break;
        case menuAction.RECEIVE_MENU:          
            return {
                ...state,
                discrimination: initialDiscriminationState
            }
        default:
            return state;
    }
    return state;
}
