import { IconButton, Button, Dialog } from "@material-ui/core";
import Grid from '@material-ui/core/Grid'
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";
import PriorityIcon from "@material-ui/icons/PriorityHighOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import i18n from 'i18next';
import * as React from "react";
import { CustomMenuContainer } from "./customMenu/CustomMenuContainer";
import { DailyMenuContainer } from "./dailyMenu/DailyMenuContainer";
import styles from "./OrderMenu.module.css";
import { FoodItem, ItemPortion, ExtraItem, MenuType, Nutrient } from "./OrderTypes";
import { styled } from '@material-ui/core/styles';


export interface TabMenuProps {
    firstTitle: string;
    secondTitle: string;
    information: string;
    dietDescription: string;
    tabChanger: any;
    activeTab: MenuType;
    isDirty: boolean;
    mealMomentIsBlocked: boolean;
    isBBF: boolean;
    isBBFReadonly: boolean;
    foodItems: { [key: number]: FoodItem };
    foodItemsPortions: { [key: number]: ItemPortion };
    foodItemsExtra: { [key: number]: ExtraItem };
}


export class TabMenuComponent extends React.Component<TabMenuProps, {}> {
    getStripedStyle(index: number) {
        return { background: index % 2 ? 'white' : 'whitesmoke' };
    }

    tmpSelectedFoodItemsNutrients: Nutrient[] = [];
    sortedSelectedFoodItemsNutrients: Nutrient[] = [];

    state = {
        openNutrients: false,      
    };

    handleCloseNutrients = () => {
        this.setState({ ...this.state, openNutrients: false });
    };

    handleClickOpenNutrients = () => {
        this.calculateNutrientDialog(this.props.foodItems, this.props.foodItemsPortions, this.props.foodItemsExtra);
    };

    calculateNutrient = (nutrient: Nutrient, foodItemId: any, portion: number ) => {
        var index = this.tmpSelectedFoodItemsNutrients && this.tmpSelectedFoodItemsNutrients.length > 0 ? this.tmpSelectedFoodItemsNutrients.findIndex(x => x.id == nutrient.id) : -1;
        if (index > -1) {
            let nut: Nutrient = {
                code: nutrient.code,
                id: nutrient.id,
                name: nutrient.name,
                sortOrder: nutrient.sortOrder,
                totCalculatedValuePerPortion: nutrient.totCalculatedValuePerPortion,
                totCalculatedWeightPerPortion: nutrient.totCalculatedWeightPerPortion,
                unit: nutrient.unit,
                value: this.tmpSelectedFoodItemsNutrients[index].value + nutrient.value,
                totCalculatedValue: this.tmpSelectedFoodItemsNutrients[index].totCalculatedValue + (nutrient.totCalculatedValuePerPortion * portion)
            }
            this.tmpSelectedFoodItemsNutrients.splice(index, 1);
            this.tmpSelectedFoodItemsNutrients.push(nut);
        }
        else {
            let nut: Nutrient = {
                code: nutrient.code,
                id: nutrient.id,
                name: nutrient.name,
                sortOrder: nutrient.sortOrder,
                totCalculatedValuePerPortion: nutrient.totCalculatedValuePerPortion,
                totCalculatedWeightPerPortion: nutrient.totCalculatedWeightPerPortion,
                unit: nutrient.unit,
                value: nutrient.value,
                totCalculatedValue: (nutrient.totCalculatedValuePerPortion * portion)
            }
            this.tmpSelectedFoodItemsNutrients.push(nut);
        }
        this.sortedSelectedFoodItemsNutrients = this.tmpSelectedFoodItemsNutrients.sort((n1: Nutrient, n2: Nutrient) => n1.sortOrder - n2.sortOrder);
    }

    calculateNutrientDialog = (foodItemsData: { [key: number]: FoodItem }, foodItemsPortionsData: { [key: number]: ItemPortion }, foodItemsExtraData: { [key: number]: ExtraItem }) =>
    {
        this.tmpSelectedFoodItemsNutrients.splice(0);

        if (foodItemsPortionsData) {
            for (const foodItemId in foodItemsPortionsData) {
                foodItemsData[foodItemId].nutrientsList.map((nutrient) => {
                    this.calculateNutrient(nutrient, foodItemId, foodItemsPortionsData[foodItemId].portion);
                })
            }
        }

        if (foodItemsExtraData) {
            for (const foodItemId in foodItemsExtraData) {
                foodItemsExtraData[foodItemId].nutrientsList.map((nutrient) => {
                    this.calculateNutrient(nutrient, foodItemId, foodItemsExtraData[foodItemId].portion);
                })
            }
        }

        if (this.sortedSelectedFoodItemsNutrients.length > 0) {
            this.setState({ ...this.state, openNutrients: true });
        }        
    }

    renderNutrientDialog = () => {
        return <Dialog classes={{ paper: styles.dialog }} onClose={this.handleCloseNutrients} aria-labelledby="customized-dialog-title" open={this.state.openNutrients} style={{ width: "100%" }} >
            <Card style={{ overflow: "auto", height: "auto" }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {i18n.t("nutrient.Titel")}
                    </Typography>
                    <Grid item xs={12}>
                        {this.sortedSelectedFoodItemsNutrients && this.sortedSelectedFoodItemsNutrients.length > 0 ?
                            <Table size="small" style={{ borderColor: "lightgrey", borderStyle: "solid", borderWidth: "thin", borderCollapse: "unset" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={{ paddingTop: 0, paddingBottom: 0 }}><Typography style={{ fontWeight: 600 }} variant="body2" color="textSecondary" component="p">{i18n.t('allergeen.Omschrijving')}</Typography></TableCell>
                                        <TableCell align="right" style={{ paddingTop: 0, paddingBottom: 0 }}><Typography style={{ fontWeight: 600 }} variant="body2" color="textSecondary" component="p">100g</Typography></TableCell>
                                        <TableCell align="right" style={{ paddingTop: 0, paddingBottom: 0 }}><Typography style={{ fontWeight: 600 }} variant="body2" color="textSecondary" component="p">{i18n.t('order.Ukeuze')}</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.sortedSelectedFoodItemsNutrients.map((nutrient) => (
                                          
                                        <TableRow>
                                            <TableCell align="left" style={{ borderBottom: "none", paddingTop: 0, paddingBottom: 0, ...this.getStripedStyle(nutrient.sortOrder) }}>
                                                <Typography variant="body2" color="textSecondary" component="p">{nutrient.name}</Typography>
                                            </TableCell>
                                            <TableCell align="right" style={{ borderBottom: "none", paddingTop: 0, paddingBottom: 0, ...this.getStripedStyle(nutrient.sortOrder) }}>
                                                <Typography variant="body2" color="textSecondary" component="p">{nutrient.value.toFixed(2) + " " + nutrient.unit.substr(0, 2) + "."}</Typography>
                                            </TableCell>
                                            <TableCell align="right" style={{ borderBottom: "none", paddingTop: 0, paddingBottom: 0, ...this.getStripedStyle(nutrient.sortOrder) }}>
                                                <Typography variant="body2" color="textSecondary" component="p">{nutrient.totCalculatedValue.toFixed(2) + " " + nutrient.unit.substr(0,2) + "."}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    
                                    ))}
                                </TableBody>
                            </Table> : null}
                        {/*</Item>*/}
                    </Grid>
                </CardContent>

                <CardActions>
                    <Button className={styles.foodDialogClose} size="small" color="primary" onClick={this.handleCloseNutrients}>
                        {i18n.t("algemeen.Sluit")}
                    </Button>
                </CardActions>
            </Card>
        </Dialog >
    }

    public render() {

        const activeTab = this.props.isBBF && this.props.isBBFReadonly ? 1 : this.props.activeTab;

        function TabLabel(string: string, isBold: boolean) {
            return <div>
                <Typography variant="subtitle1" id="title"
                    className={isBold ? styles.tabLabelBold : styles.tabLabel}>
                    {string}
                </Typography>
            </div>;
        }
        
        const renderTabMenu = () => {
            return <div>
                <Paper elevation={1} square={true}>
                    <Tabs
                        value={activeTab} //{this.props.activeTab}
                        onChange={this.props.tabChanger}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth">
                        <Tab label={TabLabel(this.props.firstTitle, false)} tabIndex={MenuType.DAILY_MENU} disabled={this.props.isBBF && this.props.isBBFReadonly} />
                        <Tab label={TabLabel(this.props.secondTitle, this.props.isDirty)} tabIndex={MenuType.CUSTOM_MENU} disabled={this.props.mealMomentIsBlocked} />

                    </Tabs>
                </Paper>
                <div className={styles.container}>
                    <div className={styles.information}>
                        {this.renderNutrientDialog()}                        
                        <IconButton aria-label="info" className={styles.infoIcon}
                            onClick={this.handleClickOpenNutrients}>                            
                            <InfoIcon></InfoIcon>
                        </IconButton>
                        <div className={styles.informationText}>
                            <Typography variant="caption">
                                {this.props.information}
                            </Typography>
                            {this.props.dietDescription && <Typography variant="caption" className={styles.diet}>
                                {i18n.t('menu.MmogelijkeKeuzes')} {this.props.dietDescription.toLowerCase()}
                            </Typography>}
                        </div>
                    </div>

                    {MenuType.DAILY_MENU === activeTab && <DailyMenuContainer />}
                    {MenuType.CUSTOM_MENU === activeTab && <CustomMenuContainer />}
                </div >
            </div >
        }
        return renderTabMenu();
    }
}