import * as React from "react";
import styles from "./seperatorComponent.module.css";
import Typography from "@material-ui/core/Typography";

export interface SeperatorComponentProps {
    text: string;
}

export class SeperatorComponent extends React.Component<SeperatorComponentProps, any> {

    public render() {
        return <div className={styles.separator}>
            <Typography variant="overline" color="primary">
                {this.props.text}
            </Typography>
        </div>;
    }
}