import * as React from "react";
import {Avatar} from "@material-ui/core";
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { Profile } from "./../profile/ProfileTypes";
import i18n from 'i18next';

export interface ProfileAvatarProps {
    profile: Profile
}

export class ProfileAvatar extends React.Component<ProfileAvatarProps, any>{

    myStyles = {
        avatar: {
            margin: 2,
            marginLeft: 10,
            marginRight: 10
        },
        orangeAvatar: {
            margin: 2,
            marginLeft: 10,
            marginRight: 10,
            color: '#fff',
            backgroundColor: deepOrange[500],
        }
    };

    public render() {
        const style = this.props.profile.isPrimary ? this.myStyles.orangeAvatar : this.myStyles.avatar;
        let avatar = this.props.profile.avatar;
        let avatarNumber = this.props.profile.avatarNumber;
        return <Avatar style={style}>
            {avatar.indexOf("|tfrsc") < 0 ? avatar : i18n.t("resource." + avatar) + " " + avatarNumber}
        </Avatar>
    }

}