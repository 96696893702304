import * as React from "react";
import { connect } from "react-redux";
import { confirmMenu, showFoodInformationDialog } from "../OrderActions";
import { FoodItemExtraComponent, FoodItemExtraProps, FoodItemExtraDispatchProps } from "./FoodItemExtraComponent";
import { OrderState, ExtraItem, Allergen, Nutrient } from "../OrderTypes";


const mapStateToProps = (state: any, ownProps: FoodItemExtraProps): FoodItemExtraProps => {
    let myOrderState = state.order as OrderState;
    let foodItemExtra: ExtraItem = myOrderState.foodItemsExtra[ownProps.id];
    if (!foodItemExtra) {
        return {
            ...ownProps,
        };
    }

    let itemPortion = foodItemExtra.portion;

    return {
        ...ownProps,
        description: foodItemExtra.description,
        portion: itemPortion ? itemPortion: 0,
        category: "Extra",
        allergens: foodItemExtra.allergens,
        allergensList: foodItemExtra.allergensList,
        nutrients: foodItemExtra.nutrients,
        nutrientList: foodItemExtra.nutrientsList,
        image_url: foodItemExtra.image_url,
        detailedDescription: foodItemExtra.detailedDescription,
    };
};

const mapDispatchToProps = (dispatch: any): FoodItemExtraDispatchProps => {
    return {
        //confirmMenu: () => {
        //    dispatch(confirmMenu(false));
        //},
        showFoodInformationDialog: (description: string, allergensList: { [key: number]: Allergen }, nutrientList: { [key: number]: Nutrient }, url_image: string, detailedDescription: string) => {
            dispatch(showFoodInformationDialog(description, allergensList, nutrientList, url_image, detailedDescription));
        },
    };
};

export const FoodItemExtraContainer = connect(
    mapStateToProps, mapDispatchToProps)(FoodItemExtraComponent) as React.ComponentClass<FoodItemExtraProps>;
