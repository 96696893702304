import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import styles from "./DailyMenu.module.css";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { Allergen, Nutrient } from "../OrderTypes";
import i18n from 'i18next';

export interface FoodItemExtraProps {
    id: number,
    portion?: number,
    category?: string,
    description?: string,
    //menuchartlinenr?: number,
    divider?: boolean,
    allergens?: boolean,
    allergensList?: Allergen[],
    nutrients?: boolean,
    nutrientList?: Nutrient[],
    image_url?: string,
    detailedDescription?: string,
}

export interface FoodItemExtraDispatchProps {
    showFoodInformationDialog: (description: string, allergensList: Allergen[], nutrientList: Nutrient[], url_image: string, detailedDescription: string) => void;
}
export class FoodItemExtraComponent extends React.Component<FoodItemExtraProps & FoodItemExtraDispatchProps, {}> {


    public render() {
        const renderAllergenenButton = () => {
            return <IconButton aria-label="info" className={styles.infoIcon}
                onClick={() => this.props.showFoodInformationDialog(this.props.description, this.props.allergensList, this.props.nutrientList, this.props.image_url, this.props.detailedDescription)}>
                <InfoIcon>
                </InfoIcon>
            </IconButton>
        }

        const renderFoodItem = () => {
            return <ListItem>
                <Typography className={styles.portionContainer} variant="body2"><strong>{this.props.portion}</strong></Typography>
                <Typography id={styles.caption} variant="caption">{i18n.t("algemeen.Portie")}</Typography>
                        <div className={styles.foodContainer}>
                            <Typography variant="body2"><strong>{this.props.description}</strong></Typography>
                </div>
                {(this.props.allergens && this.props.allergensList.length > 0) || (this.props.nutrients && this.props.nutrientList.length > 0) || this.props.detailedDescription != null ? renderAllergenenButton() : null}
            </ListItem>
        }

        if (this.props.divider) {
            return <React.Fragment>
                       {
                    renderFoodItem()
                }
                       <Divider/>
                   </React.Fragment>;
        }
        return renderFoodItem();
    }
}