import { Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withTheme, WithTheme as WithThemeProps } from '@material-ui/styles';
import i18n from 'i18next';
import * as React from "react";
import { ReactComponent as BreakIcon } from "../../../images/break.svg";
import { ReactComponent as BreakfastIcon } from "../../../images/breakfast.svg";
import { ReactComponent as BreakfastIconActive } from "../../../images/breakfast_done.svg";
import { ReactComponent as BreakfastIconNoMeal } from "../../../images/breakfast_nomeal.svg";
import { ReactComponent as BreakIconActive } from "../../../images/break_done.svg";
import { ReactComponent as BreakIconNoMeal } from "../../../images/break_nomeal.svg";
import { ReactComponent as DinnerIcon } from "../../../images/dinner.svg";
import { ReactComponent as DinnerIconActive } from "../../../images/dinner_done.svg";
import { ReactComponent as DinnerIconNoMeal } from "../../../images/dinner_nomeal.svg";
import { ReactComponent as LunchIcon } from "../../../images/lunch.svg";
import { ReactComponent as LunchIconActive } from "../../../images/lunch_done.svg";
import { ReactComponent as LunchIconNoMeal } from "../../../images/lunch_nomeal.svg";
import { MealMoment } from "../OrderTypes";
import styles from "./MealMoment.module.css";

export interface FoodButtonProps {
    data: MealMoment,
    selectMealMoment: any,
    logo: number,
}

export enum IconEnum {
    Breakfast,
    Lunch,
    Dinner,
}

class MealMomentButtonComponent extends React.Component<FoodButtonProps & WithThemeProps<Theme>, any> {

    public render() {

        const imageColor = {
            backgroundColor: this.props.theme.mealMoment.main,
        };

        const activeColor = {
            backgroundColor: this.props.theme.mealMoment.active,
        };

        const noMealColor = {
            backgroundColor: this.props.theme.cancelComponents.main,
        }

        const getNormalIcon = (id: number) => {
            switch (id) {
                case 1: //IconEnum.Breakfast:
                    return <BreakfastIcon className={styles.breakFast} />;
                case 3: //IconEnum.Lunch:
                    return <LunchIcon />;
                case 5: //IconEnum.Dinner:
                    return <DinnerIcon />;
                default:
                    return <BreakIcon />
            }
        }

        const getActiveIcon = (id: number) => {
            switch (id) {
                case 1: //IconEnum.Breakfast:
                    return <BreakfastIconActive className={styles.breakFast} />;
                case 3: //IconEnum.Lunch:
                    return <LunchIconActive />;
                case 5: //IconEnum.Dinner:
                    return <DinnerIconActive />;
                default:
                    return <BreakIconActive />
            }
        }

        const getNoMealIcon = (id: number) => {
            switch (id) {
                case 1: //IconEnum.Breakfast:
                    return <BreakfastIconNoMeal className={styles.breakFast} />;
                case 3: //IconEnum.Lunch:
                    return <LunchIconNoMeal />;
                case 5: //IconEnum.Dinner:
                    return <DinnerIconNoMeal />;
                default:
                    return <BreakIconNoMeal />
            }
        }

        const getIcon = (id: number) => {
            if (this.props.data.isNoMeal) {
                return getNoMealIcon(id);
            }
            else {
                if (this.props.data.isOrdered) {
                    return getActiveIcon(id);
                } else {
                    return getNormalIcon(id);
                }
            }
        }

        const title = this.props.data.isBBF ? this.props.data.title + " - " + i18n.t("bbf.Buffet") : this.props.data.title;

        const description = this.props.data.description.indexOf("dd.") < 0 ? this.props.data.description : this.props.data.description.replace("dd.", i18n.t("day." + this.props.data.dayName));
        
        const splitDescription = description.split('\n').map(str => <div>{str.indexOf("|tfrsc") < 0 ? str : i18n.t("resource." + str)}</div>); //str
        
        return <Button className={[styles.foodButton, this.props.data.toggle ? null : styles.foodButtonDisabled].join(" ")}
            variant="contained" onClick={() => this.props.selectMealMoment(this.props.data)}>
            <span style={this.props.data.isNoMeal ? noMealColor : this.props.data.isOrdered ? activeColor : imageColor} className={styles.imageContainer}>
                {getIcon(this.props.data.id)}
            </span>
            <div>
                <Typography id="title" variant="h6">{title}</Typography>
                <Typography id="title" variant="body2" style={{ marginInlineEnd: '1em' }}>                    
                    {splitDescription}
                </Typography>
            </div>
        </Button>
    }
}

export default withTheme<Theme, typeof MealMomentButtonComponent>(MealMomentButtonComponent);
