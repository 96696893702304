import * as React from "react";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import styles from "./FoodItem.module.css";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { Allergen, Nutrient } from "./OrderTypes";
import i18n from 'i18next';

export interface FoodItemOwnProps {
    id: number;
    amount: number;
    showDivider: boolean;
    mealMomentIsBlocked: boolean;
}

export interface FoodItemProps {
    category: string,
    description: string,
    menuchartlinenr: number,
    allergens: boolean,
    allergensList: Allergen[],
    nutrients: boolean,
    nutrientList: Nutrient[],
    image_url?: string,
    detailedDescription: string,
    courseGroupId: number;
}

export interface FoodItemDispatchProps {
    showFoodInformationDialog: (description: string, allergensList: Allergen[], nutrientList: Nutrient[],url_image: string, detailedDescription: string) => void;
    openInCustomMenu: (courseGroupId: number) => void;
}
export class FoodItemComponent extends React.Component<FoodItemProps & FoodItemOwnProps & FoodItemDispatchProps, {}> {

    public render() {
        const renderInfoButton = () => {
            return <IconButton aria-label="info" className={styles.infoIcon}
                onClick={() => this.props.showFoodInformationDialog(this.props.description, this.props.allergensList, this.props.nutrientList, this.props.image_url, this.props.detailedDescription)}>
                <InfoIcon>
                </InfoIcon>
            </IconButton>
        }

        const renderFoodItem = () => {
            return <ListItem>
                <Typography className={styles.portionContainer} variant="body2"><strong>{this.props.amount}</strong></Typography>
                <Typography id={styles.caption} variant="caption">{i18n.t("algemeen.Portie")}</Typography>

                <Button onClick={() => this.props.mealMomentIsBlocked ? false : this.props.openInCustomMenu(this.props.courseGroupId)} className={styles.foodContainer}>
                    <div>
                        <Typography variant="caption">{this.props.category}</Typography>
                        <Typography variant="body2"><strong>{this.props.description}</strong></Typography>
                    </div>
                </Button>
                {(this.props.allergens && this.props.allergensList.length > 0) || (this.props.nutrients && this.props.nutrientList.length > 0) || this.props.detailedDescription != null ? renderInfoButton() : null}
            </ListItem>
        }
        //
        //
        if (this.props.showDivider) {
            return <React.Fragment>
                {
                    renderFoodItem()
                }
                <Divider />
            </React.Fragment>;
        }
        return renderFoodItem();
    }
}