import * as React from "react";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import styles from "./CustomMenu.module.css";
import { FoodPortionContainer } from "./FoodPortionContainer";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface FoodCourseGroupProps {
    title?: string;
    caption?: string;
    isExceeded?: boolean;
    helpText?: string;
    foodItems?: number[];
    courseID: number;
    courseGroupID: number;
}

export interface FoodCourseGroupDispatchProps {
    addPortion(id: number, amount: number): void;
    subtractPortionHandler(id: number, amount: number): void;
}

export class FoodCourseGroupComponent extends React.Component<FoodCourseGroupProps & FoodCourseGroupDispatchProps, {}> {
    public render() {

        const renderList = () => {
            return <List>
                       {
                    this.props.foodItems.map((id, i) =>
                        <FoodPortionContainer id={id} key={i} portion={0} addPortionHandler={this.props.addPortion} subtractPortionHandler={this.props.subtractPortionHandler}/>
                    )
                }
                   </List>
        }

        //const helpText = this.props.helpText ? this.props.helpText : <span>&nbsp;&nbsp;</span>; 
        let title =this.props.title ? <Typography className={styles.summary} color={this.props.isExceeded ? "error" : "initial"} variant="h6">{this.props.title}</Typography> : <span/>;

        const subTitle = this.props.helpText ? this.props.helpText : this.props.caption;

        const renderFoodPortion = () => {
            return <React.Fragment>
                {title}
                <Typography className={styles.summary} variant="caption">{subTitle}</Typography>
                <span>&nbsp;&nbsp;</span>
                {
                    renderList()
                }
                   </React.Fragment>;
        }


        if (this.props.foodItems) {
            return renderFoodPortion();
        } else {
            return <CircularProgress />;
        }
    }
}