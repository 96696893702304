import * as React from "react";
import styles from "./HelpComponent.module.css";
import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import i18n from 'i18next';

export interface HelpTipProps {
    code: string;
    }

export class HelpTipComponent extends React.Component<HelpTipProps, any>{

    public constructor(props: HelpTipProps) {
        super(props);
        this.state = {
            code: this.props.code,
            description: i18n.t("help.Geenhelp"),
            isOpen: false
        };
    }

    public open() {
        this.setState({
            ...this.state,
            isOpen: true
        });
    }

    public close() {
        this.setState({
            ...this.state,
            isOpen: false
        });
    }

    handleClickOpenPatientNumberInfo = () => {
        axios.get<string>("/api/hospital/helptip?code=" + this.props.code + "&culture=" + i18n.language)
            .then(response => {
                const tip = response.data;
                this.setState({
                    ...this.state,
                    description: tip.indexOf("|tfrsc") < 0 ? tip : i18n.t("resource." + tip), //tip,
                    isOpen: true
                });
            })
    }

    public render() {
        const renderInfoIcon = () => {
            return <div><IconButton aria-label="info" className={styles.infoIcon}
                onClick={this.handleClickOpenPatientNumberInfo}> 
                <InfoIcon>
                </InfoIcon>
            </IconButton>
                {renderDialog()}
            </div>
        }

        const renderDialog = () => {
            return <Dialog
                open={this.state.isOpen}
                onClose={this.close.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Tip"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.close.bind(this)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        }

        return renderInfoIcon();

    }
}