import { log } from "util";

export class StatePersistance {

    static loadState(key:string, initialState : any) {
        try {
            let serializedState = sessionStorage.getItem(key);

            if (serializedState === null) {
                return initialState;
            }

            return JSON.parse(serializedState);
        }
        catch (err) {
            return initialState;
        }
    }

    static saveState(key: string, state: any) {
        try {
            let serializedState = JSON.stringify(state);
            sessionStorage.setItem(key, serializedState);
        }
        catch (err) {
            log(err);
        }
    }

    static clearState(key: string) {
        try {
            sessionStorage.removeItem(key);
        }
        catch (err) {
        }
    }
}
