import createMuiTheme, { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

declare module "@material-ui/core/styles/createMuiTheme" {
    interface Theme {
        topHeader: {
            main: React.CSSProperties["color"]
        },
        informationHeader: {
            main: React.CSSProperties["color"]
        },
        overviewHeader: {
            main: React.CSSProperties["color"]
        },
        mealMoment: {
            main: React.CSSProperties["color"],
            active: React.CSSProperties["color"]

        },
        cancelComponents: {
            main: React.CSSProperties["color"],
            contrastText: React.CSSProperties["color"],
        },
        logo: string,
    }
    interface ThemeOptions {
        topHeader?: {
            main?: React.CSSProperties["color"]
        },
        informationHeader?: {
            main?: React.CSSProperties["color"]
        },
        overviewHeader?: {
            main?: React.CSSProperties["color"]
        },
        mealMoment?: {
            main?: React.CSSProperties["color"],
            active?: React.CSSProperties["color"]
        },
        cancelComponents?: {
            main?: React.CSSProperties["color"],
            contrastText?: React.CSSProperties["color"],
        },
        logo?: string,
    }
}

export const getTheme = (options: ThemeOptions) => {
    return createMuiTheme(options);
}

//const firstTheme = (options: ThemeOptions) => {
//    return createMuiTheme({
//        palette: {
//            primary: {
//                main: "#4c9fce",
//                contrastText: "#ffffff",
//            },
//            secondary: { //Top header 
//                main: "#90d5df",
//                contrastText: "#ffffff",
//            },
//            text: { primary: "#0d435a" }, //Text color of titles
//        },
//        informationHeader: { //Color of smaller header components and mealmoment background images
//            main: "#c8e9ef",
//        },
//        mealMoment: { //Color of smaller header components and mealmoment background images
//            main: "#c8e9ef",
//        },
//        cancelComponents: { //Cancel components, like no meal requested
//            main: "#f37160",
//            contrastText: "#ffffff",
//        },
//        logo: "logo_cegeka.png", //Image has to be placed in public folder!
//        overrides: {
//            MuiBackdrop: { 
//                root: { // Name of the rule
//                    backgroundColor: "#ffffff80",
//                },
//            },
//        },
//        ...options,
//    });
//}

//const secondTheme = (options: ThemeOptions) => {
//    return createMuiTheme({
//        palette: {
//            primary: {
//                main: "#2ab141",
//                contrastText: "#ffffff",
//            },
//            secondary: { //Top header 
//                main: "#99df90",
//                contrastText: "#ffffff",
//            },
//            text: { primary: "#0d435a" }, //Text color of titles
//        },
//        tertiary: { //Color of smaller header components
//            main: "#c8efcf",
//        },
//        quaternary: { //Cancel components, like no meal requested
//            main: "#f37160",
//            contrastText: "#ffffff",
//        },
//        mainLogo: "logo_gza.png", //Image has to be placed in public folder!
//        overrides: {
//            MuiBackdrop: {
//                root: { // Name of the rule
//                    backgroundColor: "#ffffff80",
//                },
//            },
//        },
//        ...options,
//    });
//}