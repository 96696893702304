import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { ApplicationState } from "../../store";
import { LoginCredentials } from "./authTypes";
import { ExpiredContainer } from "./ExpiredComponent";
import { SeperatorComponent} from "./../../components/SeperatorComponent";
import styles from "./LoginComponent.module.css";
import { ConfigAuthenticationState } from "./../config/ConfigTypes";
import { FormsLoginComponent, FormsLoginContainer } from "./forms/FormsLoginComponent";
import { CsamLoginContainer } from "./csam/CsamLoginComponent";
import { withTranslation, WithTranslation } from 'react-i18next';

export interface LoginComponentProps {
    authenticationConfiguration: ConfigAuthenticationState;
    isLoading: boolean;
    isAuthenticated: boolean;
    isExpired: boolean;
    logonFailedMessage: string;
    isLoggingOut: boolean;
}

export interface LoginComponentDispatchProps {
    login: (credentials: LoginCredentials) => void;
}



export class LoginComponent extends React.Component<LoginComponentProps & WithTranslation, any> {

    public render() {

        if (this.props.isExpired) {
            return <ExpiredContainer />
        }

        if (this.props.isAuthenticated) {
            return <Redirect to="/" />
        }

        const title = this.props.isLoading === false ?
            <span>{this.props.t("login.Welkom1")} <br /> {this.props.t("login.Welkom2")}</span> :
            <span>{this.props.t("login.Bezig")}</span>
          
        const showSeperatorComponentAfterForms = this.props.authenticationConfiguration.isFormsAuthenticationEnabled && this.props.authenticationConfiguration.isCsamAuthenticationEnabled;
        let content;

        if (this.props.isLoading) {
            return <div>
                <Typography variant="h5" gutterBottom className={styles.welcomeMessage}>
                    {title}
                </Typography>
                <CircularProgress/>
            </div>
        } else {
            return <div>
                <Typography variant="h5" gutterBottom className={styles.welcomeMessage}>
                    {title}
                </Typography>
                {this.props.authenticationConfiguration.isFormsAuthenticationEnabled && <div className={styles.loginContainer}><FormsLoginContainer /></div>}
                {showSeperatorComponentAfterForms && <SeperatorComponent text={this.props.t('login.OF')} />}
                {this.props.authenticationConfiguration.isCsamAuthenticationEnabled && <div className={styles.loginContainer}><CsamLoginContainer /></div>}
                <hr />
            </div>
        }
    } 
}

const mapStateToProps = (state: ApplicationState): LoginComponentProps => {
    return {
        authenticationConfiguration: state.config.authentication,
        isLoading: state.auth.isAuthenticating,
        isAuthenticated: state.auth.isAuthenticated,
        isExpired: state.auth.isExpired,
        logonFailedMessage: state.auth.logonFailedMessage,
        isLoggingOut: state.auth.isLoggingOut
    }
}

export const LoginContainer = connect(mapStateToProps)(withTranslation()(LoginComponent)) as unknown as React.ComponentClass;

