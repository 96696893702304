import * as React from 'react'; 
import { Typography, Theme, Paper } from '@material-ui/core';
import { WithTheme as WithThemeProps, withTheme } from '@material-ui/styles';
import styles from "./Profile.module.css";
import { DisclaimerContainer } from './DisclaimerContainer';
import { ProfileContainer } from './ProfileContainer';

export interface ProfilePageProps {
    organizationName: string;
    isDisclaimerTop: boolean;
}

class ProfilePageComponent extends React.Component<ProfilePageProps, any>{

    public render() {
        return <div>

            <Typography noWrap variant="h6" className={styles.welcome} style={{ margin: 20 }}>
                {this.props.organizationName}
            </Typography>

            {this.props.isDisclaimerTop && <DisclaimerContainer />}
            <ProfileContainer />
            {!this.props.isDisclaimerTop && <DisclaimerContainer />}
        </div>
    }
}

export default withTheme<Theme, typeof ProfilePageComponent>(ProfilePageComponent);