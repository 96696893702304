import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import i18n from 'i18next';
import * as moment from 'moment';
import * as React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { ApplicationState } from "../../../store";
import { HelpTipComponent } from "../../Help/HelpComponent";
import { Logon, LogonFailed } from "./../authActions";
import { LoginCredentials } from "./../authTypes";
import { ExpiredContainer } from "./../ExpiredComponent";
import styles from "./FormsLoginComponent.module.css";

export interface FormsLoginComponentState {
    credentials: LoginCredentials;
    openPatientNumberInfo: boolean;
    patientNumberHelpInfo: string;
}

export interface FormsLoginComponentProps {
    isLoading: boolean;
    isAuthenticated: boolean;
    isExpired: boolean;
    logonFailedMessage: string;
    isLoggingOut: boolean;
}

export interface FormsLoginComponentDispatchProps {
    login: (credentials: LoginCredentials) => void;
}

interface HelpTip {
    isSuccess: boolean;
    description: string;
}

interface HelpTipProps {
    code: string;
}

export class FormsLoginComponent extends React.Component<FormsLoginComponentProps & FormsLoginComponentDispatchProps & WithTranslation, FormsLoginComponentState> {   
    constructor(props: any) {      
        super(props);
        this.state = {
            credentials: {
                patientNumber: "",
                birthdate: null,
                preferredCulture: i18n.language
            },
            openPatientNumberInfo: false,
            patientNumberHelpInfo: null
        };
    }

    handleDateChange = (date: moment.Moment) => {
        this.setState({
            credentials: {
                ...this.state.credentials,
                birthdate: date
            }
        });
    }

    handleClosePatientNumberInfo = () => {
        this.setState({ openPatientNumberInfo: false });
    };

    private login(event: any) {
        this.state.credentials.preferredCulture = i18n.language;

        event.preventDefault();        
        this.props.login(this.state.credentials);
    }

    public render() {
        if (this.props.isExpired) {
            return <ExpiredContainer />
        }

        if (this.props.isAuthenticated) {
            return <Redirect to="/" />
        }

        const newBirthdate = () => {
            return <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={i18n.language}>
                <KeyboardDatePicker
                    label={this.props.t("login.GebDat")}
                    cancelLabel={this.props.t("algemeen.Annuleer")}
                    okLabel={this.props.t("algemeen.Ok")}                    
                    value={this.state.credentials.birthdate}
                    format="DD/MM/YYYY"
                    onChange={this.handleDateChange}
                    className={styles.inputField}
                    placeholder="01/01/1901"
                    invalidDateMessage={this.props.t("login.OngeldigeGebDat")}
                    animateYearScrolling={false} />
            </MuiPickersUtilsProvider>
        }

        const patientNumber = () => {
            return <TextField
                id="patient_number"
                InputProps={{
                    endAdornment: (
                        <HelpTipComponent code="INFOPATNR" />
                    ),
                }}
                label={this.props.t("login.PatId")}
                margin="normal"
                inputMode="numeric"
                disabled={this.props.isLoading}
                value={this.state.credentials.patientNumber}
                onChange={this.onPatientNumberChanged.bind(this)}
                className={styles.inputField}
            />
        }

        const loginButton = () => {

            return <Button type="submit"
                className={styles.loginButton} disabled={this.props.isLoading} variant="contained" size="large" color="primary">
                {this.props.t("algemeen.Aanmelden")}
                   </Button>
        }

        let logonFailed;

        if (this.props.logonFailedMessage) {
            logonFailed = <Typography variant="caption" color="error">
                {this.props.logonFailedMessage}
            </Typography>
        }

        return <form
            onSubmit={this.login.bind(this)}>
            <React.Fragment>
                <span>{this.props.t("login.Welkom3")}</span>
                {newBirthdate()}
                {patientNumber()}
            </React.Fragment>
            <div>
                {loginButton()}
            </div>
            <div>
                {logonFailed}
            </div>
        </form>;

    }

    private onPatientNumberChanged(event: any) {
        this.setState({
            credentials: {
                ...this.state.credentials,
                patientNumber: event.target.value,
            },
        });
    }

}

const mapStateToProps = (state: ApplicationState): FormsLoginComponentProps => {
    return {
        isLoading: state.auth.isAuthenticating,
        isAuthenticated: state.auth.isAuthenticated,
        isExpired: state.auth.isExpired,
        logonFailedMessage: state.auth.logonFailedMessage,
        isLoggingOut: state.auth.isLoggingOut
    }
}

const mapDispatchToProps = (dispatch: any): FormsLoginComponentDispatchProps => {
    return {
        login: (credentials: LoginCredentials) => {
            dispatch(loginAction(credentials));            
        }
    };
};


const loginAction = (credentials: LoginCredentials) => (dispatch: any) => {    
    if (credentials.birthdate === null) {
        dispatch(LogonFailed(i18n.t("login.FoutGebDat")));
        //dispatch(LogonFailed("Gelieve geboortedatum in te voeren."));        
        return;
    }
    if (credentials.patientNumber === "") {
        dispatch(LogonFailed(i18n.t("login.FoutPatId")));
        //dispatch(LogonFailed("Gelieve patiëntcode in te voeren."));
        return;
    }
    dispatch(Logon(credentials));
};


export const FormsLoginContainer = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormsLoginComponent)) as unknown as React.ComponentClass;