import * as React from "react";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Typography from "@material-ui/core/Typography";
import styles from "./CustomMenu.module.css";
import Fab from '@material-ui/core/Fab';
import { Hidden, IconButton, Theme } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { WithTheme as WithThemeProps, withTheme } from '@material-ui/styles';
import { style } from "@material-ui/system";
import { Allergen, Nutrient } from "../OrderTypes";


export interface FoodPortionProps {
    id: number;
    portion: number;
    possiblePortions?: number[];
    description?: string;
    menuChartLineNr?: number;
    allergens?: boolean,
    allergensList?: Allergen[],
    nutrients?: boolean,
    nutrientList?: Nutrient[],
    status?: boolean;
    addPortionHandler?: any;
    subtractPortionHandler?: any;
    isLocked?: boolean;
    isLockedSet?: boolean,
    lockedReason?: string;
    image_url?: string,
    detailedDescription?: string,
    courseGroupIsBlocked?: boolean,
    mealMomentIsBBF?: boolean,
    mealMomentIsBBFSelectable?: boolean,
}

export interface FoodPortionDispatchProps {
    showFoodInformationDialog: (description: string, allergensList: Allergen[], nutrientList: Nutrient[], url_image: string, detailedDescription: string) => void;
}

class FoodPortionComponent extends React.Component<FoodPortionProps & FoodPortionDispatchProps & WithThemeProps<Theme>, {}> {

    public render() {
        const secondaryColor = this.props.theme.palette.secondary.main;
        const miniIconStyle = {
            backgroundColor: secondaryColor,
        };

        const isFirstPortion = this.props.portion === 0;
        const isLastPortion =
            this.props.portion === this.props.possiblePortions[this.props.possiblePortions.length - 1];
        const isBBF = this.props.mealMomentIsBBF;

        const renderAllergenenButton = () => {
            return <IconButton aria-label="info" className={styles.infoIcon}
                onClick={() => this.props.showFoodInformationDialog(this.props.description, this.props.allergensList, this.props.nutrientList, this.props.image_url, this.props.detailedDescription)}>
                <InfoIcon></InfoIcon>
            </IconButton>
        }

        /*don't show button but take up the space (visibility: hidden)*/
        const renderNoAllergenenButton = () => {
            return <IconButton aria-label="no info" className={styles.infoIcon} style={{ visibility: "hidden" }}>
                <HighlightOffOutlinedIcon></HighlightOffOutlinedIcon>
            </IconButton>
        }

        const renderPortionButtons = () => {
            return <span className={styles.actionsContainer}>
                <Fab variant="round" color="secondary" aria-label="Remove" className={styles.miniIcon} onClick={() => this.props.subtractPortionHandler(this.props.id, this.props.portion)}
                    disabled={isFirstPortion || this.props.courseGroupIsBlocked}>
                    <RemoveIcon />
                </Fab>
                <Typography id={styles.counter} variant="body1">
                    {this.props.portion ? this.props.portion.toLocaleString() : ''}
                </Typography>
                <Fab variant="round" color="secondary" aria-label="Add" className={styles.miniIcon} onClick={() => this.props.addPortionHandler(this.props.id, this.props.portion)}
                    disabled={this.props.isLocked || isLastPortion || this.props.courseGroupIsBlocked}>
                    <AddIcon />
                </Fab>
            </span>
        }

        const renderPortion = () => {
            return <ListItem disableGutters divider className={styles.listItem}>
                <Typography color={this.props.isLockedSet ? "error" : "initial"} variant="subtitle1" className={styles.itemDescription}>
                    {this.props.description}
                </Typography>
                {(this.props.allergens && this.props.allergensList.length > 0) || (this.props.nutrients && this.props.nutrientList.length > 0) || this.props.detailedDescription ? renderAllergenenButton() : renderNoAllergenenButton()}
                {this.props.mealMomentIsBBF && !this.props.mealMomentIsBBFSelectable ? null : renderPortionButtons()}
            </ListItem >
        }

        if (this.props.description) {
            return renderPortion();
        }
        return <div></div>;
    }
}

export default withTheme<Theme, typeof FoodPortionComponent>(FoodPortionComponent);
