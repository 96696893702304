import * as React from "react";
import { connect } from "react-redux";
import { MenuState } from "../../menu/MenuTypes";
import { confirmMenu, setOrderDailyMenu, showDailyMenu } from "../OrderActions";
import { OrderState } from "../OrderTypes";
import DailyMenuComponent, { DailyMenuDispatchProps, DailyMenuProps } from "./DailyMenuComponent";

const mapStateToProps = (state: any): DailyMenuProps => {
    let myMenuState = state.menu as MenuState;
    let myOrderStat = state.order as OrderState
    let myDailyMenu = myMenuState.menu.dailyMenu;
    let foodItemsExtra = myOrderStat.foodItemsExtra;

    let myFoodItems: number[] = [];
    let myFoodItemsExtra: number[] = [];
    let myFoodItemsChartNumbers: number[] = [];
    let sortmyFoodItemsChartNumbers: number[] = [];
    
    for (const key in myDailyMenu) {
        myFoodItemsChartNumbers.push(myDailyMenu[key].menuChartLineNr);
        sortmyFoodItemsChartNumbers = myFoodItemsChartNumbers.sort((n1,n2) => n1 - n2);
    }

    for (var i = 0; i < sortmyFoodItemsChartNumbers.length; i++) {
        for (const key in myDailyMenu) {
            if (myDailyMenu[key].menuChartLineNr == sortmyFoodItemsChartNumbers[i]) {
                myFoodItems.push(myDailyMenu[key].foodItemID);
            }
        }
    }

    for (const keyEIp in foodItemsExtra) {
        myFoodItemsExtra.push(foodItemsExtra[keyEIp].id);
    }

    return {
        foodItems: myFoodItems,
        portions: myDailyMenu,
        foodItemsExtra: myFoodItemsExtra,
        loading: myMenuState.loading,
        mealMomentIsNoMeal: myOrderStat.mealMoment.isNoMeal,
        mealMomentIsOrdered: myOrderStat.mealMoment.isOrdered,
        mealMomentIsBlocked: myOrderStat.mealMoment.blocked,
        mealMomentReadOnly: !myOrderStat.mealMoment.isBBFSelectable,
        mealMoment: myOrderStat.mealMoment
    };
};

const mapDispatchToProps = (dispatch: any): DailyMenuDispatchProps => {
    return {
        confirmMenu: () => {
            dispatch(confirmMenu(false, true));
        },
        confirmNoMenu: () => {
            dispatch(confirmMenu(true, true)); 
        },
        setOrderDailyMenu: () => {
            dispatch(setOrderDailyMenu());
        },
        showDailyMenu: (mealMoment) => {
            dispatch(showDailyMenu(mealMoment));

        }
    };
};

export const DailyMenuContainer = connect(
    mapStateToProps, mapDispatchToProps)(DailyMenuComponent) as React.ComponentClass;
 
