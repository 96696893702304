import * as React from "react";
import { connect } from "react-redux";
import { MenuState } from "../../menu/MenuTypes";
import { FoodCourseGroupProps, FoodCourseGroupDispatchProps, FoodCourseGroupComponent } from "./FoodCourseGroupComponent";
import { FoodItem } from "../OrderTypes";
import { addPortion, subtractPortion } from "../OrderActions";
import { ItemPortion } from "../OrderTypes";
import { discriminateFoodCourseGroup } from "../discrimination/discriminationSelectors";


const mapStateToProps = (state: any, ownProps: FoodCourseGroupProps): FoodCourseGroupProps => {
    let myMenuState = state.menu as MenuState;
    let courseGroup = myMenuState.menu.courseGroups[ownProps.courseGroupID];
    if (!courseGroup) {
        return {
            ...ownProps,
        };
    }

    let title = courseGroup.title;
    const course = myMenuState.menu.courses[ownProps.courseID];

    if (course && course.courseGroups.length === 1 && course.title.toUpperCase() === title.toUpperCase()) {
        title = undefined;
    }

    let IDs = courseGroup.foodItems;
    let captionResult = discriminateFoodCourseGroup(ownProps.courseGroupID, state);

    return {
        ...ownProps,
        title: title,
        isExceeded: captionResult.isExceeded,
        caption: courseGroup.caption,
        helpText: captionResult.helpText,
        foodItems: IDs,
    };
    //caption: captionResult.helpText == null || captionResult.helpText == "" ? courseGroup.caption : captionResult.helpText,
}

/**
 * if the foodItem already exists in our order, update the portion value which we get from our existing Order state
 * @param foodItem
 * @param itemPortions
 */
const updatePortion = (foodItem: FoodItem, itemPortions: ItemPortion[]): FoodItem => {
    for (let item of itemPortions) {
        if (item.foodItemID === foodItem.id) {
            return {
                ...foodItem,
//                portion: item.portion,
            }
        }
    }
    return foodItem;
}

const mapDispatchToProps = (dispatch: any): FoodCourseGroupDispatchProps => {
    return {
        addPortion: (id: number, portion: number) => {
            dispatch(addPortion(id, portion));
        },
        subtractPortionHandler: (id: number, portion: number) => {
            dispatch(subtractPortion(id, portion));
        }
    }
}

export const FoodCourseGroupContainer = connect(
    mapStateToProps, mapDispatchToProps)(FoodCourseGroupComponent) as React.ComponentClass<FoodCourseGroupProps>;
