export const initialConfigState: ConfigState = {
    authentication: {
        isFormsAuthenticationEnabled: true,
        isCsamAuthenticationEnabled: false,
        isOneTimeLinkAuthenticationEnabled: false
    },
    loadDataProfiles: false,
    helpTipAvailable: false,
    translationActive: false,
    appOffline: false,
    changeDiet: true,
    showDiet: true,
    mealPictures: true,  
    guestEaters: true,
    languages: {}
}

export interface ConfigAuthenticationState {
    isFormsAuthenticationEnabled: boolean;
    isCsamAuthenticationEnabled: boolean;
    isOneTimeLinkAuthenticationEnabled: boolean;
}

export interface ConfigState {
    authentication: ConfigAuthenticationState;
    loadDataProfiles: boolean;
    helpTipAvailable: boolean;
    appOffline: boolean;
    changeDiet: boolean;
    showDiet: boolean;
    mealPictures: boolean; 
    guestEaters: boolean; 
    translationActive: boolean;
    languages: {[key: string]: string};    
}
