import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps, Switch } from "react-router";
import { HeaderContainer } from "./components/HeaderContainer";
import styles from "./components/MasterContainer.module.css";
import { ClientSessionProvider } from './modules/auth/ClientSessionProvider';
import { LoginContainer } from "./modules/auth/LoginComponent";
import { ErrorComponent } from "./modules/error/ErrorComponent";
import { ErrorSnackbarContainer } from "./modules/error/ErrorSnackbarContainer";
import { HomeContainer } from "./modules/home/HomeContainer";
import { MealMomentContainer } from './modules/order/mealMoment/MealMomentContainer';
import { OrderContainer } from "./modules/order/OrderContainer";
import { ProfilePageContainer } from "./modules/profile/ProfilePageContainer";

interface PrivateRouteReduxProps {
    isAuthenticated: boolean;
    isLoading: boolean;
}

interface PrivateRouteReduxDispatchProps {
    preAuthenticateUser(): void;
}

export interface PrivateRouteProps extends RouteProps {

}

class PrivateRouteComponent extends React.Component<PrivateRouteProps & PrivateRouteReduxProps & PrivateRouteReduxDispatchProps> {
     
    public render() {

        if (this.props.isLoading) {
            return <div>Bezig met aanmelden ...</div>;
        }

        let { component: Component, isAuthenticated: isAuthenticated, ...rest } = this.props;

        return (
            <ClientSessionProvider>
                <Route
                    {...rest}
                    render={(props: any) => {
                        return this.props.isAuthenticated ? <Component {...props} /> : <Redirect to="/account/login" />;
                        }} />
           </ClientSessionProvider>
        );
    }
}

let mapState = (state: any): PrivateRouteReduxProps => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isLoading: state.auth.isAuthenticating 
    }
};

let mapDispatch = (dispatch: any): PrivateRouteReduxDispatchProps => {
    return {
        preAuthenticateUser: () => null
    };
}

const PrivateRoute = connect<PrivateRouteReduxProps, PrivateRouteReduxDispatchProps, PrivateRouteProps>(mapState, mapDispatch)(PrivateRouteComponent) as unknown as React.ComponentClass<PrivateRouteProps, any>;
 
export const routes = (
    <div>
        <HeaderContainer />
        <ErrorSnackbarContainer />
        <div className={styles.container}>
            <Switch>
                <PrivateRoute exact path="/" component={() => <HomeContainer />} />
                <PrivateRoute exact path="/menu" component={() => <MealMomentContainer />} />
                <PrivateRoute exact path="/order" component={() => <OrderContainer />}/>
                <PrivateRoute exact path="/profile" component={() => <ProfilePageContainer/>} />
                <PrivateRoute exact path="/404" component={() => <ErrorComponent />} />
                <Route path="/account/login" component={() => <LoginContainer />} />
                
            </Switch>
        </div>
    </div>
); 