import * as React from "react";
import { connect } from "react-redux";
import { MenuState } from "../../menu/MenuTypes";
import FoodPortionComponent, { FoodPortionProps, FoodPortionDispatchProps } from "./FoodPortionComponent";
import { OrderState, Allergen, Nutrient } from "../OrderTypes";
import { discriminateFoodItem } from '../discrimination/discriminationSelectors';
import { showFoodInformationDialog } from "../OrderActions";

const mapStateToProps = (state: any, ownProps: FoodPortionProps): FoodPortionProps => {

    let myMenuState = state.menu as MenuState;
    let myOrderState = state.order as OrderState;
    let item = myMenuState.menu.foodItems[ownProps.id];
    let orderItem = myOrderState.itemPortions[ownProps.id];
    let portion = 0;

    let lockResult = discriminateFoodItem(ownProps.id, item.courseGroupId, state);
    let courseGroup = myMenuState.menu.courseGroups[item.courseGroupId];
    let mealMoment = myOrderState.mealMoment;

    if (!item) {
        return {
            ...ownProps,
        }
    } else if (orderItem) {
        portion = orderItem.portion;
    }
    return {
        ...ownProps,
        portion: portion,
        possiblePortions: item.possiblePortions,
        description: item.description,
        menuChartLineNr: item.menuChartLineNr,
        isLocked: lockResult.isLocked,
        isLockedSet: lockResult.isLockedSet,
        lockedReason: lockResult.lockedReason,
        allergens: item.allergens,
        allergensList: item.allergensList,
        nutrients: item.nutrients,
        nutrientList: item.nutrientsList,
        image_url: item.image_url,
        detailedDescription: item.detailedDescription,
        courseGroupIsBlocked: courseGroup.blocked,
        mealMomentIsBBF: mealMoment.isBBF,
        mealMomentIsBBFSelectable: mealMoment.isBBFSelectable,
    };
}

const mapDispatchToProps = (dispatch: any): FoodPortionDispatchProps => {
    return {
        showFoodInformationDialog: (description: string, allergensList: Allergen[], nutrientList: Nutrient[], url_image: string, detailedDescription: string) => {
            dispatch(showFoodInformationDialog(description, allergensList, nutrientList, url_image, detailedDescription));
        },
    }
}

export const FoodPortionContainer = connect(
    mapStateToProps, mapDispatchToProps)(FoodPortionComponent) as React.ComponentClass<FoodPortionProps>;
