import axios from "axios";
import { showSnackbar } from "./modules/menu/MenuActions";
import { ApplicationState } from "./store";
import { replace } from 'connected-react-router';
import { ProfileState } from "./modules/profile/ProfileTypes";
import { AuthState } from "./modules/auth/authTypes";
import { LogonExpired } from './modules/auth/authActions';

export default function configureAxios(store: any) {
    axios.defaults.headers.get['Content-Type'] = 'application/json';
    axios.defaults.headers.get['Accept'] = 'application/json';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Accept'] = 'application/json';
    axios.defaults.timeout = 60000;
    axios.defaults.responseType = 'json';

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        const profileState = store.getState().profile as ProfileState;
        let active_id = "-1";
        if (profileState && profileState.activeProfileID) {
            active_id = profileState.activeProfileID;
        } else {
            const authState = store.getState().auth as AuthState;
            if (authState && authState.pid) {
                profileState.activeProfileID = authState.pid;
            }
        }
        if (!config.headers.active_id) {
            config.headers = { ...config.headers, 'active_id': profileState.activeProfileID };
        }

        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, async function (error) {
        console.log(error);
        if (error.request.status === 404 || error.request.status === 500) {
            store.dispatch(showSnackbar("Something went wrong", "error", 10000));
            //store.dispatch(replace('404')); //TODO error page here?
        }
        //unauthorized
        if (error.request.status === 401) {
            if (!(store.getState() as ApplicationState).auth.isExpired) {
                await store.dispatch(LogonExpired());
            }
        }
        // Do something with response error
        return Promise.reject(error);
    });
}