import axios, { AxiosResponse } from "axios";
import { push } from "connected-react-router";
import { TextAlignProperty } from 'csstype';
import { AuthState } from "../auth/authTypes";
import { getDailyMenu, getMenu, receiveMealMoment } from "../menu/MenuActions";
import { MenuState } from "../menu/MenuTypes";
import { ApplicationState } from './../../store';
import { Allergen, Nutrient, FoodItem, ItemPortion, MealMoment, MenuType, OrderItem, OrderState, SelectMenuOptions } from "./OrderTypes";
import { ProfileState, Profile } from "../profile/ProfileTypes";
import i18n from 'i18next';

export const orderAction = {
    SELECT_MEAL_MOMENT: "SELECT_MEAL_MOMENT",
    SELECT_MENU: "SELECT_MENU",
    SELECT_COURSE_PANEL: "SELECT_COURSE_PANEL", 
    GO_NEXT_STEP: "GO_NEXT_STEP",
    CHANGE_PORTION: "CHANGE_PORTION", 
    SHOW_SNACKBAR: "SHOW_SNACKBAR",
    SHOW_CUSTOM_DIALOG: "SHOW_CUSTOM_DIALOG",
    SHOW_FOOD_INFORMATION_DIALOG: "SHOW_FOOD_INFORMATION_DIALOG",
    CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
    CLOSE_CUSTOM_DIALOG: "CLOSE_CUSTOM_DIALOG",
    CLOSE_FOOD_INFORMATION_DIALOG: "CLOSE_FOOD_INFORMATION_DIALOG",
    CONFIRM_MENU: "CONFIRM_MENU",
    CONFIRM_NOMEAL: "CONFIRM_NOMAEL",
    CLEAR_ORDER: "CLEAR_ORDER",
    SET_ORDER_DAILY_MENU: "SET_ORDER_DAILY_MENU",
    SET_ORDER_EXTRAS: "SET_ORDER_EXTRAS",
    SET_OVERVIEW_PANEL: "SET_OVERVIEW_PANEL",
    RECEIVE_FOOD_INFORMATION_DIALOG_IMAGE: "RECEIVE_FOOD_INFORMATION_DIALOG_IMAGE",
}

export const selectMealMoment = (data: MealMoment) => {
    return (dispatch: any, getState: any) =>  {
        if (!data.toggle) { //if mealmoment was disabled return original state
            dispatch(showSnackbar(data.reason, "warning", 5000));
            if (!data.blocked) {
                dispatch({
                type: orderAction.SELECT_MEAL_MOMENT,
                payload: data
                });
                return;
            }            
        }
        dispatch(getMenu(data));
    }
}

export const showDailyMenu = (data: MealMoment) => {
    return (dispatch: any, getState: any) => {
        if (!data.toggle) { //if mealmoment was disabled return original state
            dispatch(showSnackbar(data.reason, "warning", 5000));
            if (!data.blocked) {
                dispatch({
                    type: orderAction.SELECT_MEAL_MOMENT,
                    payload: data
                });
                return;
            }
        }
        dispatch(getDailyMenu(data));
    }
}

export const selectMenu = (menuType: MenuType, selectFoodCourse: number = null, selectFoodCourseGroup: number = null) => {
    return (dispatch: any, state: any) => {
          
        let menu = (state().menu as MenuState).menu;
        let options: SelectMenuOptions = {
            menuType,
            focusCourse : 0,
            focusCourseGroup : 0
        };

        if (selectFoodCourseGroup) {
            let courseGroup = menu.courseGroups[selectFoodCourseGroup];
            options.focusCourseGroup = selectFoodCourseGroup;
            options.focusCourse = courseGroup.courseID;
        } else if (selectFoodCourse) {
            options.focusCourse = selectFoodCourse;
        }

        if (options.focusCourse === 0) {
            //defaults first course
            options.focusCourse = menu.courseKeys[0];
        }

        dispatch({
            type: orderAction.SELECT_MENU,
            payload: options,
        });
    }
}
 

export const goNextStep = (id: number) => {
    return (dispatch: any, getState: any) => {
        var state = { ...getState() };
        let myMenuState = state.menu as MenuState;
        let nextKeyIndex = myMenuState.menu.courseKeys.findIndex(x => x == id) + 1;
        let nextKey = myMenuState.menu.courseKeys[nextKeyIndex];
        dispatch({
            type: orderAction.GO_NEXT_STEP,
            payload: nextKey,
        });
    }
}

export const showSnackbar = (text: string, variant: string, duration: number, textAlign: TextAlignProperty = "center") => {
    return {
        type: orderAction.SHOW_SNACKBAR,
        payload: {
            text: text,
            variant: variant,
            duration: duration,
            textAlign: textAlign
        },
    };
}

export const showCustomDialog = (title: string, content: string, buttonText: string) => {
    return {
        type: orderAction.SHOW_CUSTOM_DIALOG,
        payload: {
            title: title,
            content: content,
            buttonText: buttonText,
        },
    };
}

export const showFoodInformationDialog = (description: string, allergens: { [key: number]: Allergen }, nutrients: { [key: number]: Nutrient }, url_image: string, detailedDescription: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(getFoodInformationDialogImage(url_image));
        dispatch( {
            type: orderAction.SHOW_FOOD_INFORMATION_DIALOG,
            payload: {
                description: description,
                allergens: allergens,
                nutrients: nutrients,
                detailedDescription: detailedDescription,
            },
        });
    }

}

export const getFoodInformationDialogImage = (image_url: string) => {
    if (image_url == "") return;
    return (dispatch: any, getState: any) => {

        const imagesSupported = (getState() as ApplicationState).config.mealPictures;
        if (!imagesSupported) {
            return;
        }

        return axios({
            url: "/api/storage/image",
            method: "get",
            params: {
                image: image_url,
            },
            responseType: 'arraybuffer',
        }).then(response => {
            dispatch(receiveFoodInformationDialogImage(response.data, response.headers['content-type']));
        });
    };
}


export const receiveFoodInformationDialogImage = (data: any, contentType: string) => {
    return {
        type: orderAction.RECEIVE_FOOD_INFORMATION_DIALOG_IMAGE,
        payload: { data: data, contentType: contentType },
    };
}

export const closeSnackbar = () => {
    return {
        type: orderAction.CLOSE_SNACKBAR,
    };
}

export const closeCustomDialog = () => {
    return {
        type: orderAction.CLOSE_CUSTOM_DIALOG,
    };
}

export const closeFoodInformationDialog = () => {
    return {
        type: orderAction.CLOSE_FOOD_INFORMATION_DIALOG,
    };
}

export const setOverviewPanel = (open: boolean) => {
    return {
        type: orderAction.SET_OVERVIEW_PANEL,
        payload: open,
    };
}

interface OrderMenuResult {
    isSuccess: boolean;
    errorMessage: string;
}

export const confirmMenu = (noOrder: boolean, dailyMenu: boolean) => {
    return (dispatch: any, getState: any) => {
    const state = { ...getState() };
    const mealmoment: MealMoment = state.order.mealMoment;
    const newMomentAfterOrder = {
        ...mealmoment,
        isNoMeal: noOrder,
        isOrdered: !noOrder,
        toggle: true
    };
    return axios({
        url: "/api/order/order", 
        method: "post",
        data: getOrder(state, noOrder, dailyMenu),
    })
        .then((response: AxiosResponse<OrderMenuResult>) => {
            let result = response.data;
                if (result && result.isSuccess) { 
                    dispatch({
                        type: orderAction.CONFIRM_MENU,
                        payload: {
                        newMomentAfterOrder
                    }
                });
                dispatch(receiveMealMoment(newMomentAfterOrder));
                dispatch(showSnackbar(i18n.t('order.MenuOk'), "success", 10000));
                dispatch(push('/menu'));
                } else {
                    let errorMessage = translateError(result.errorMessage);
                    dispatch(setOverviewPanel(false));
                    dispatch(showSnackbar(errorMessage, "error", null, "left")); //filter error response of line break characters
                }
        });
    }
}
 
export const addPortion = (id: number, portion: number) => {
    return (dispatch: any, getState: any) => {
        var state = { ...getState() };
        let myMenuState = state.menu as MenuState;
        let myFoodItem = myMenuState.menu.foodItems[id];

        let possiblePortions: number[] = myFoodItem.possiblePortions;
        let indexCurrentPortion = possiblePortions.indexOf(portion);

        if (indexCurrentPortion + 1 < possiblePortions.length) {

            let newPortion = possiblePortions[indexCurrentPortion + 1];
            
            dispatch({
                type: orderAction.CHANGE_PORTION,
                payload: {
                    id: id,
                    currentPortion: portion,
                    newPortion: newPortion,
                    menuChartLineNr: myFoodItem.menuChartLineNr
                }
            });
        }
    }
}

export const subtractPortion = (id: number, portion: number) => {
    return (dispatch: any, getState: any) => {

        var state = { ...getState() };

        let myMenuState = state.menu as MenuState;
        let myFoodItem = myMenuState.menu.foodItems[id];

        let possiblePortions: number[] = myFoodItem.possiblePortions;
        let indexCurrentPortion = possiblePortions.indexOf(portion) == -1 ? 1 : possiblePortions.indexOf(portion);
         
        if (indexCurrentPortion > 0) { 
            let newPortion = possiblePortions[indexCurrentPortion - 1];;
            dispatch({
                type: orderAction.CHANGE_PORTION,
                payload: {
                    id: id, 
                    currentPortion: portion,
                    newPortion: newPortion,
                    menuChartLineNr: myFoodItem.menuChartLineNr
                }
            });
        }
    }
}

export const translateError = (error: string) => {
    if (error.indexOf("|tfrsc") < 0)
        return error;

    let found = true;
    while (found) {
        if (error.indexOf("|tfrsc|") >= 0) {
            let tfrsc = error.substr(error.indexOf("|tfrsc|"), 9).trim();
            tfrsc = isNumber(tfrsc.slice(-1)) ? tfrsc.substr(0, tfrsc.length-1) : tfrsc;
            let tfrsc_translated = i18n.t("resource." + tfrsc.trim());
            error = error.replace(tfrsc, tfrsc_translated);
        }
        if (error.indexOf("|tfrscw|") >= 0) {
            let tfrscw = error.substr(error.indexOf("|tfrscw|"), 10).trim();
            tfrscw = isNumber(tfrscw.slice(-1)) ? tfrscw.substr(0, tfrscw.length - 1) : tfrscw;
            let tfrscw_translated = i18n.t("resource." + tfrscw.trim());
            error = error.replace(tfrscw, tfrscw_translated);
        }

        let position = error.indexOf("|tfrsc");
        if (position < 0)
            found = false
    }
    return error;
}
function isNumber(str: any) {
    return isNaN(str.slice(-1)) ? true : false;
}

export const clearOrder = () => {
    return {
        type: orderAction.CLEAR_ORDER,
    };
} 

export const setOrderDailyMenu = () => {
    return (dispatch: any, getState: any) => {
        //var state = { ...getState() };
        //dispatch({
        //    type: orderAction.SET_ORDER_DAILY_MENU,
        //    payload: { ...state.menu.menu.dailyMenu },
        //});
    }
}

export const setOrderExtraFoodItems = (data: any) => {
    return (dispatch: any, getState: any) => {
        var state = { ...getState() };
        dispatch({
            type: orderAction.SET_ORDER_EXTRAS,
            payload: { data },
        });
    }
}

const getOrder = (state: any, noOrder: boolean, dailyMenu: boolean) => {
    let myMenuState: MenuState = state.menu;
    let myAuthState: AuthState = state.auth;
    let myOrderState: OrderState = state.order;
    let myProfileState = state.profile as ProfileState;
    let activeProfile: Profile = myProfileState.profiles.find(pr => pr.id == myProfileState.activeProfileID);
    
    return { 
        MealMomentKey: myOrderState.mealMoment.mealMomentKey,
        MenuKey: myMenuState.menu.menuKey,
        Items: getOrderItems(myOrderState, myMenuState),
        NoOrder: noOrder,
        DailyMenu: dailyMenu,
        Profile: activeProfile,
        pid: myAuthState.pid
    };
}

const getOrderItems = (myOrderState: OrderState, myMenuState: MenuState) => {
    let foodItems: { [key: number]: FoodItem } = myMenuState.menu.foodItems;
    let itemPortions: { [key: number]: ItemPortion } = myOrderState.itemPortions;
    let orderItems: OrderItem[] = [];

    for (const key in itemPortions) {
        let foodItemID = itemPortions[key].foodItemID;
        if (foodItemID != 0) {
            let newOrderItem: OrderItem = {
                orderKey: foodItems[foodItemID].orderKey,
                portion: itemPortions[key].portion,
            }
            orderItems.push(newOrderItem);
        }
    }
    return orderItems;
}
