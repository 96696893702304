import * as React from "react";
import { connect } from "react-redux";
import { MenuState } from "../../menu/MenuTypes";
import { OrderState } from "../../order/OrderTypes";
import { CustomMenuProps, CustomMenuDispatchProps, CustomMenuComponent } from "./CustomMenuComponent";
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state: any): CustomMenuProps => {
    let myMenuState = state.menu as MenuState;
    let orderState = state.order as OrderState;
    return {
        courseIDs: myMenuState.menu.courseKeys,
        loading: myMenuState.loading,
        isDirty: orderState.isDirty,
        isBBF: orderState.mealMoment.isBBF,
        isBBFSelectable: orderState.mealMoment.isBBFSelectable
    };
}

const mapDispatchToProps = (dispatch: any): CustomMenuDispatchProps => {
    return {
    }
}


export const CustomMenuContainer = connect(
    mapStateToProps, mapDispatchToProps)(withTranslation()(CustomMenuComponent)) as React.ComponentClass;
