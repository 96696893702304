import * as React from "react";
import { Route, RouteProps, Switch, Redirect, Prompt } from "react-router";
import styles from "./CustomMenu.module.css";
import { FoodCourseContainer } from "./FoodCourseContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { OverviewMenuContainer } from "../overviewMenu/OverviewMenuContainer";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { WithTranslation } from 'react-i18next';

export interface CustomMenuProps {
    courseIDs: number[];
    loading: boolean;
    isDirty: boolean;
    isBBF: boolean;
    isBBFSelectable: boolean;
}

export interface CustomMenuDispatchProps {
}


export class CustomMenuComponent extends React.Component<CustomMenuProps & CustomMenuDispatchProps & WithTranslation, any> {

    public render() {

        const renderNewMenu = () => {
            if (this.props.loading) {
                return <CircularProgress />
            }
             
            else if (this.props.courseIDs.length > 0) {
                return <div className={styles.container}>
                     
                    {
                        this.props.courseIDs.map((id, i) =>
                            <FoodCourseContainer expanded={false} isExceeded={false} id={id} key={i} />
                        )
                    }
                </div>
            } else {
                return <div>
                    <Paper elevation={1} square={true}>
                        <List id={styles.list}>
                            {
                                <Typography variant="body1">{this.props.t("order.Cmenu")}</Typography>
                            }
                        </List>
                    </Paper>
                </div>;
            }
        }

        return <React.Fragment>
            {renderNewMenu()}
            <OverviewMenuContainer></OverviewMenuContainer>
        </React.Fragment>
    }
}

