import axios from "axios";
import { push } from 'connected-react-router';
import { Store } from "redux";
import { StatePersistance } from '../../statePersistance';
import { ApplicationState } from "../../store";
import { AuthenticationResult, AuthState, LoginCredentials } from "./authTypes";
import i18n from 'i18next';

export const actionsEnum = {
    LOGON_USER: "LOGON_USER",
    LOGON_USER_STARTED: "LOGON_USER_STARTED",
    RECEIVE_USER: "RECEIVE_USER",
    LOGOUT: "LOGOUT",
    LOGOUT_STARTED: "LOGOUT_STARTED",
    LOGON_FAILED: "LOGON_FAILED",
    LOGON_EXPIRED: "LOGON_EXPIRED"
}

const preflightSessionAxios = axios;

export async function preflightSession(store: Store<ApplicationState>) {
    try {
        var response = await preflightSessionAxios.get("/api/account/userinfo");
        if (response) {
            const data = response.data as AuthenticationResult;
            if (data && data.isSuccess) {
                await store.dispatch({
                    type: actionsEnum.RECEIVE_USER,
                    payload: data,
                });
            }
        }
    } catch (err) {
        //do nothing ..
        console.error(err);
    }

}

const logonUser = () => (dispatch: any, getState: any) => {
    dispatch({
        type: actionsEnum.LOGON_USER
    });
    dispatch(push('/account/login'));
}

const LogonUser = (patientUser: AuthenticationResult) => (dispatch: any, getState: any) => {
    dispatch({
        type: actionsEnum.RECEIVE_USER,
        payload: patientUser,
    });
}

export const LogonExpired = () => {
    return {
        type: actionsEnum.LOGON_EXPIRED
    };
}

export const ConfirmLogonExpired = () => (dispatch: any, getState: any) => {
    //dispatch({
    //    type: actionsEnum.LOGON_USER
    //})

    //post logout clears cookies    
    
    axios.post("/account/logout").        
        finally(() => {
            //always try reload window
            StatePersistance.saveState("cmeal.state", {});
            window.open('/', '_self');
        });
}

export const LogonFailed = (message: string) => {
    return {
        type: actionsEnum.LOGON_FAILED,
        payload: message.indexOf("|tfrsc") < 0 ? message  : i18n.t("resource." + message),
    };
}

export const Logon = (credentials: LoginCredentials) => async (dispatch: any, getState: any) => {

    await dispatch({
        type: actionsEnum.LOGON_USER_STARTED
    });

    return axios.post("/account/login", {
        patientNumber: credentials.patientNumber,
        birthdate: credentials.birthdate.format('DD/MM/YYYY'),
        preferredCulture: credentials.preferredCulture
    }).then(
        response => {

            var data = response.data as AuthenticationResult;
            if (data.errorMessage)
                data.errorMessage = data.errorMessage.indexOf("|tfrsc") < 0 ? data.errorMessage : i18n.t("resource." + data.errorMessage);

            if (data.isSuccess) {
                data.isLocalLogon = true;
                dispatch(LogonUser(data));
                let params: URLSearchParams = new URLSearchParams(window.location.search);
                let returnUrl: string = params.get("returnUrl");

                if (returnUrl) {
                    i18n.changeLanguage(credentials.preferredCulture);
                    window.location.href = returnUrl;
                } else {
                    dispatch(push('/'));
                }


            } else {
                dispatch(LogonFailed(i18n.t(data.errorMessage)));
            }

        }).catch((err) => {
            console.log(err.response);
            dispatch(LogonFailed(err.response.data));
        });
}

export const Logoff = () => (dispatch: any, getState: any) => {

    const auth = getState().auth as AuthState;

    dispatch({
        type: actionsEnum.LOGOUT_STARTED
    });

    axios.post("/account/logout").
        then(() => {
            if (!auth.isLocalLogon) {
                StatePersistance.saveState("cmeal.state", {});
                window.open('/', '_self');
            } else {
                dispatch({
                    type: actionsEnum.LOGOUT
                });
            }
        });
}
