import { goBack, push } from 'connected-react-router';
import * as React from "react";
import { connect } from "react-redux";
import { Logoff } from "../modules/auth/authActions";
import { closeCustomDialog } from "../modules/order/OrderActions";
import { saveProfile, getLanguages } from "../modules/profile/ProfileActions";
import { Profile } from "../modules/profile/ProfileTypes";
import { ApplicationState } from "../store";
import HeaderComponent, { HeaderDispatchProps, HeaderProps } from "./HeaderComponent";

const mapStateToProps = (state: ApplicationState, ownProps: HeaderProps): HeaderProps => {
        return {
        ...ownProps,
        isTranslationActive: state.config.translationActive && ((state as any).router.location.pathname === "/menu" || (state as any).router.location.pathname === "/account/login"),
        isProfileEnabled: state.profile.activeProfileID != null && state.config.loadDataProfiles,
        profiles: state.profile.profiles,
        cmealLanguages: state.profile.languages,
        activeProfileID: state.profile.activeProfileID,
        configLanguages: state.config.languages,
            showMenuIcon: state.auth.isAuthenticated,
        showReturnIcon: state.auth.isAuthenticated &&
            ((state as any).router.location.pathname === "/order" || (state as any).router.location.pathname === "/profile"),
            customDialog: state.order.customDialog,            
    };
}

const mapDispatchToProps = (dispatch: any): HeaderDispatchProps => {
    return {
        saveProfile: (patientNumber: string, isDisclaimerChecked: boolean, preferredDietNumber: number, preferredLanguage: number, isUsingApp: boolean, newCulture: string, profile: Profile) => dispatch(saveProfile(patientNumber, isDisclaimerChecked, preferredDietNumber, preferredLanguage, isUsingApp, newCulture, profile)),
        goTo: (route: string) => {
            if (route === 'back') {
                dispatch(goBack());
            }
            else {
                dispatch(push(route));
            }
        },
        logout: () => {
            dispatch(Logoff());
        },
        closeCustomDialog: () => {
            dispatch(closeCustomDialog());
        },
        getLanguages: () => {
            dispatch(getLanguages());
        }
    };
} 

export const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps)
    (HeaderComponent) as React.ComponentClass<HeaderProps>;