import * as React from "react";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import styles from "./DailyMenu.module.css";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { FoodItemContainer } from "../FoodItemContainer";
import { FoodItemExtraContainer } from "../dailyMenu/FoodItemExtraContainer";
import Dialog from "@material-ui/core/Dialog";
import { ItemPortion, MealMoment } from "./../OrderTypes";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WithTheme as WithThemeProps, withTheme } from '@material-ui/styles';
import { Theme } from "@material-ui/core";
import { withTranslation, WithTranslation } from 'react-i18next';

export interface DailyMenuProps {
    foodItems: number[],
    foodItemsExtra: number[];
    loading: boolean,
    portions: { [key: number]: ItemPortion },
    mealMomentIsNoMeal: boolean,
    mealMomentIsOrdered: boolean,
    mealMomentIsBlocked: boolean,
    mealMomentReadOnly: boolean,
    mealMoment: MealMoment
}

export interface DailyMenuDispatchProps {
    confirmMenu: () => void;
    confirmNoMenu: () => void;
    showDailyMenu: (mealMoment: MealMoment) => void;
    setOrderDailyMenu: () => void;
}

class DailyMenuComponent extends React.Component<DailyMenuProps & DailyMenuDispatchProps & WithThemeProps<Theme> & WithTranslation, any> {
    state = {
        openOrder: false,
        openNoOrder: false,
        isDailyMenu: false,
        isLoading: this.props.loading
    };

    handleClickOpenOrder = () => {
        this.setState({ openOrder: true });
    };

    handleClickOpenNoOrder = () => {
        this.setState({ openNoOrder: true });
    };

    handleClose = () => {
        this.setState({ openOrder: false, openNoOrder: false, showDailyMenu: false });
    };

    handleConfirmOrder = () => {
        this.props.confirmMenu();
        this.handleClose();
    }

    handleConfirmNoOrder = () => {
        this.props.confirmNoMenu();
        this.handleClose();
    }

    handleShowDailyMenu = () => {
        this.props.showDailyMenu(this.props.mealMoment);
        this.setState({ isDailyMenu: true });
        
    }

    public render() {
        const cancelColors = this.props.theme.cancelComponents;
        const cancelButtonColor = {
            backgroundColor: cancelColors.main,
            color: cancelColors.contrastText,
        };

        const style = this.props.foodItems.length > 0 && this.props.mealMomentIsOrdered ? { display: 'none' } : {};
        const isDisabled = this.props.mealMomentIsBlocked || this.props.mealMoment.isBBF && !this.props.mealMoment.isBBFSelectable;

        const renderDailyMenu = () => {

            if (this.props.foodItems.length == 0 && this.props.mealMomentIsOrdered) {
                return <div>
                    <List id={styles.list}>
                        {
                            <Typography variant="body1">{this.props.t("order.DmenuNiets")}</Typography>
                        }
                    </List>
                    {this.props.foodItemsExtra.length > 0 &&
                        < Typography variant="caption">{this.props.t("order.DmenuExtra")}</Typography>
                    }
                    {this.props.foodItemsExtra.length > 0 &&
                        <Paper elevation={1} square={true}>
                            <List id={styles.list}>
                                {
                                    this.props.foodItemsExtra.map((food: number, i: number) => {
                                        if (this.props.foodItemsExtra.length !== i + 1) //if not last item return element with divider
                                        {
                                            return <FoodItemExtraContainer id={food} key={i} divider={true} />
                                        }
                                        else return <FoodItemExtraContainer id={food} key={i} divider={false} />
                                    })
                                }
                            </List>
                        </Paper>
                    }

                    <Button id={styles.button} variant="contained" color="primary" style={style} onClick={this.handleClickOpenOrder} disabled={isDisabled}>
                        {this.props.t("order.DmenuDag")}
                    </Button>
                    {confirmationDialog()}

                    {this.props.foodItemsExtra.length > 0 &&
                        <Button id={styles.button} variant="contained" style={isDisabled ? {} : cancelButtonColor} onClick={this.handleClickOpenNoOrder} disabled={isDisabled}>
                            {this.props.t("order.DmenuGeen")}
                        </Button>                        
                    }
                    {confirmationNoMeuDialog()}
                </div>;                
            }
            else
            if (this.props.foodItems.length > 1 || this.props.foodItems.length === 1 && this.props.foodItems[0] !== 0) {
                return <div>
                    <Paper elevation={1} square={true}>
                        <List id={styles.list}>
                            {
                                this.props.foodItems.map((id: number, i) => {

                                    //if not last item return element with divider
                                    let showDivider = this.props.foodItems.length !== i + 1;

                                    let portion = this.props.portions[id];
                                    let amount = 0;
                                    if (portion) {
                                        amount = portion.portion;
                                    }

                                    return <FoodItemContainer id={id} key={i} showDivider={showDivider} amount={amount} mealMomentIsBlocked={this.props.mealMomentIsBlocked} />
                                })
                            }
                        </List>
                    </Paper>
                    {this.props.foodItemsExtra.length > 0 &&
                        <Typography variant="caption">EXTRA'S</Typography>
                    }
                    {this.props.foodItemsExtra.length > 0 &&
                        <Paper elevation={1} square={true}>
                            <List id={styles.list}>
                                {
                                    this.props.foodItemsExtra.map((food: number, i: number) => {
                                        if (this.props.foodItemsExtra.length !== i + 1) //if not last item return element with divider
                                        {
                                            return <FoodItemExtraContainer id={food} key={i} divider={true} />
                                        }
                                        else return <FoodItemExtraContainer id={food} key={i} divider={false} />
                                    })
                                }
                            </List>
                        </Paper>
                    }

                    <Button id={styles.button} variant="contained" color="primary" style={style} onClick={this.handleClickOpenOrder} disabled={isDisabled}>
                        {this.props.t("order.DmenuDag")}
                    </Button>
                    {confirmationDialog()}

                    <Button id={styles.button} variant="contained" style={isDisabled ? {} : cancelButtonColor} onClick={this.handleClickOpenNoOrder} disabled={isDisabled}>
                        {this.props.t("order.DmenuGeen")}
                    </Button>
                    {confirmationNoMeuDialog()}

                </div>;
            }

            // When a user specified he did not want a meal, the backend will return a daily menu with 1 empty item ->
            else 
            if (this.props.foodItems.length === 1 && this.props.foodItems[0] === 0) {
                return <div>
                    <Paper elevation={1} square={true}>
                        <List id={styles.list}>
                            {
                                <Typography variant="body1">{this.props.t("order.DmenuGmlt")}</Typography>
                            }
                        </List>
                    </Paper>
                    <Button id={styles.button} variant="contained" color="primary" onClick={this.handleShowDailyMenu} disabled={this.props.mealMomentIsBlocked}>
                        {this.props.t("order.Mvd")}
                    </Button>
                    {confirmationDialog()}
                </div>;
            }
            else {
                return <div>
                    <Paper elevation={1} square={true}>
                        <List id={styles.list}>
                            {
                                <Typography variant="body1">{this.props.mealMoment.isBBFSelectable ? this.props.t("bbf.NietGekozen") : this.props.t("order.Cmenu")}</Typography>
                            }
                        </List>
                    </Paper>
                    <Button id={styles.button} variant="contained" style={cancelButtonColor} onClick={this.handleClickOpenNoOrder} disabled={isDisabled}>
                        {this.props.t("order.DmenuGeen")}
                    </Button>
                    {confirmationNoMeuDialog()}
                </div>;
            }
                

        }

        const confirmationDialog = () => {
            return <Dialog
                open={this.state.openOrder}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{this.props.t('order.DmenuDag')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.t("order.DmenuBestel")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleConfirmOrder} color="primary" autoFocus>
                        {this.props.t("algemeen.Bevestigen")}
                    </Button>
                    <Button onClick={this.handleClose} color="primary">
                        {this.props.t("algemeen.Annuleer")}
                    </Button>
                </DialogActions>
             </Dialog>
        }

        const confirmationNoMeuDialog = () => {
            return <Dialog
                open={this.state.openNoOrder}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{this.props.t("order.DmenuGeen")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.t("order.DmenuBestelniet")}
                           </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleConfirmNoOrder} color="primary" autoFocus>
                        {this.props.t("algemeen.Bevestigen")}
                    </Button>
                    <Button onClick={this.handleClose} color="primary">
                        {this.props.t("algemeen.Annuleer")}
                     </Button>
                </DialogActions>
            </Dialog>
        }

        if (!this.props.loading) {
            return renderDailyMenu();
        }
        else {
            return <CircularProgress style={{ display: "none" }} />;
        }

    }
}

export default withTranslation()(withTheme<Theme, typeof DailyMenuComponent>(DailyMenuComponent));
