import { Drawer, Theme, Typography } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBack from "@material-ui/icons/ArrowBack";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import Taal from '@material-ui/icons/Language';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import { withTheme, WithTheme as WithThemeProps } from '@material-ui/styles';
import axios from "axios";
import i18n from 'i18next';
import * as React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { CustomDialogStatus } from "../modules/order/OrderTypes";
import { Language, Profile } from "../modules/profile/ProfileTypes";
import styles from "./Header.module.css";
//import navigator from './browserLookups/navigator.js';
//import detectBrowserLanguage from 'detect-browser-language'

export interface HeaderProps {
    showReturnIcon?: boolean;
    showMenuIcon?: boolean; 
    isProfileEnabled?: boolean;
    profiles?: Profile[],
    cmealLanguages?: Language[],
    activeProfileID?: string,
    isTranslationActive?: boolean;
    configLanguages?: {};
    customDialog?: CustomDialogStatus;
}

export interface HeaderDispatchProps {
    goTo: (route: string) => void;
    logout: () => void;
    closeCustomDialog: () => void;
    saveProfile: (patientNumber: string, isDisclaimerChecked: boolean, preferredDietNumber: number, preferredLanguageNumber: number, isUsingApp: boolean, newCulture: string, profile: Profile) => void;
    getLanguages: () => void;
}

export class HeaderComponent extends React.Component<HeaderProps & HeaderDispatchProps & WithThemeProps<Theme> & WithTranslation, any>{
    state = {
        openLogout: false,
        openMenu: false,
        openAbout: false,
        clientVersion: this.props.t("versie.ClientVersion"),
        backendVersion: this.props.t("versie.BackendVersion"),
        info: '',
        openLanguages: false
    }

    componentDidMount() {
        //const l = new languageDetector();
        ////var lg = l.detect('navigator');
        //var browserLanguage = i18n.language;
        //this.state.info = browserLanguage;
        ////var browserLanguageSplitted = browserLanguage.split("-");

        //i18n.changeLanguage(browserLanguage);
    }
    
    handleClickOpenLogout = () => {
        this.setState({ ...this.state, openMenu: false, openLanguages: false, openAbout: false, openLogout: true });
    };

    handleClickOpenAbout = () => {
        axios.get<string>("/api/hospital/helptip?code=ABOUT&culture="+i18n.language)
            .then(response => {

                axios.get<string>("/home/info")
                    .then(inforesponse => {
                        const inforesponseText = inforesponse.data;
                        this.setState({ ...this.state, openMenu: false, openLanguages: false, openAbout: true, openLogout: false, backendVersion: responseText, clientVersion: inforesponseText });
                    })

                const responseText = response.data;
                this.setState({ ...this.state,  });
            })
    };

    handleLogout = () => {
        this.setState({ ...this.state, openLogout: false });
        this.props.logout();
    };

    handleCloseLogout = () => {
        this.setState({ ...this.state, openLogout: false });
    };

    handleCloseAbout = () => {
        this.setState({ ...this.state, openAbout: false });
    };

    handleToggleLanguage = (open: boolean) => () => {
        this.setState({ ...this.state, openLanguages: open });
    };

    handleToggleMenu = (open: boolean) => () => {
        this.setState({ ...this.state, openMenu: open });
    };
    
    goTo = (route: string) => {
        this.setState({ ...this.state, openMenu: false, openLanguages: false });
        this.props.goTo(route);
    }   

    menuList = () => {
        if (this.props.profiles.length > 0 && this.props.cmealLanguages == null) {
            this.props.getLanguages();
        }
        let renderProfileItem = this.props.isProfileEnabled &&
            <ListItem button key={"Profiel"} onClick={() => { this.goTo('profile') }}>
            <ListItemIcon><PersonIcon /></ListItemIcon>
            <ListItemText primary={this.props.t('profiel.Profiel')} />
        </ListItem>

        return <div className={styles.menu}>            
            <List>
                <ListItem button key={"home"} onClick={() => { this.goTo('') }}>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary={this.props.t("profiel.Home")} />
                </ListItem>

                {renderProfileItem}

            <ListItem button key={"about"} onClick={this.handleClickOpenAbout}>
                <ListItemIcon><InfoIcon /></ListItemIcon>
                <ListItemText primary={this.props.t('profiel.About')} />
            </ListItem>

                <ListItem button key={"log uit"} onClick={this.handleClickOpenLogout}>
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                <ListItemText primary={this.props.t('algemeen.Afmelden')} />
                </ListItem>
            </List>
        </div>
    };

    LanguagesList = () => {
        let langKeys = Object.keys(this.props.configLanguages);
        let langNames: string[] = Object.values(this.props.configLanguages);
        let langNamesImg: string[] = [];

        langNames.map((key: string, t) => {
            langNamesImg.push("../images/" + key.trim() + ".png");
        })

        let list =        
            langKeys.map((key: string, i) => {              
                return <MenuItem value={key} onClick={(e) => this.handleLanguageChanged(key)} >
                    <img src={require("../images/" + langNames[i].trim() + ".png").default} style={{ maxWidth: '10%', marginRight: '5px' }} />
                    <ListItemText>{langNames[i]}</ListItemText> 
                </MenuItem>
            })
        

        return <div className={styles.menu}>
                {list}
            </div>
    };

    menu = () => (
        <Drawer anchor="right" open={this.state.openMenu} onClose={this.handleToggleMenu(false)}>
            {this.menuList()}
        </Drawer>
    );

    languages = () => (
        <Drawer anchor="right" open={this.state.openLanguages} onClose={this.handleToggleLanguage(false)}>
            {this.LanguagesList()}
        </Drawer>
    );

    headerColor = {
        backgroundColor: this.props.theme.topHeader.main,
    };

    handleLanguageChanged = (preferredLanguageCulture: string) => {
        if (this.props.profiles.length > 0) {
            let ap: Profile;
            let preferredLanguageId: number = 0;

            for (var i = 0; i < this.props.profiles.length; i++) {
                if (this.props.profiles[i].id == this.props.activeProfileID) {
                    if (this.props.cmealLanguages && this.props.cmealLanguages.length > 0) {
                        preferredLanguageId = this.props.cmealLanguages.find(x => x.culture.trim().toUpperCase() == preferredLanguageCulture.trim().toUpperCase()).id;
                    }                    
                   
                    this.props.profiles[i].preferredLanguage = preferredLanguageId;
                    this.props.profiles[i].preferredLanguageCulture = preferredLanguageCulture;

                    ap = this.props.profiles[i];
                }
            }
            
            this.props.saveProfile(ap.id, true, ap.preferredDiet, ap.preferredLanguage, !ap.isUsingApp, preferredLanguageCulture, ap);
        }                                

        i18n.changeLanguage(preferredLanguageCulture);
        this.setState({ ...this.state, openLanguages: false });
    };

    renderHeader = () => {        
        return <AppBar position="static" style={this.headerColor}>
            <Toolbar className={styles.toolbar}>
                <div style={{ marginLeft: 'auto'}}>
                    <span className={styles.headerVisual}>
                        <img id={styles.logo} src={process.env.PUBLIC_URL + this.props.theme.logo} />
                        <Typography variant="h6">C-Meal</Typography>
                    </span>
                </div>
                <div style={{ marginLeft: 'auto'}}>
                    <IconButton id={styles.returnIcon} aria-label={this.props.t('profiel.Terug')} onClick={() => { this.goTo('menu') }}
                        style={{ display: this.props.showReturnIcon ? 'block' : 'none' }}>
                        <ArrowBack />
                    </IconButton>
                    <div>
                        <IconButton id={styles.logoutIcon} aria-label={this.props.t('profiel.Open_menu')} onClick={this.handleToggleMenu(true)}
                            style={{ padding: 0, marginLeft: -10, display: this.props.showMenuIcon ? 'block' : 'none' }}>
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <div>
                        <IconButton id={styles.languageIcon} style={{ padding: 0, marginLeft: 12, display: (!this.props.showReturnIcon && this.props.isTranslationActive) ? 'block' : 'none' }} onClick={this.handleToggleLanguage(true)}>
                        {/*<img src={Taal} style={{ "maxWidth": "90%" }} />*/}
                            <Taal />
                        </IconButton>
                    </div>
                </div>
            </Toolbar>
        </AppBar>;
    }


    confirmationDialog = () => {
        return <Dialog
            open={this.state.openLogout}
            onClose={this.handleCloseLogout}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {this.props.t("algemeen.AfmeldenText")}
                    </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseLogout} color="primary">
                    {this.props.t("algemeen.Annuleren")}
                    </Button>
                <Button onClick={this.handleLogout} color="primary" autoFocus>
                    {this.props.t("algemeen.Afmelden")}
                    </Button>
            </DialogActions>
        </Dialog>
    }

    customDialog = (title: string, content: string, buttonText: string) => {
        return <Dialog onClose={this.props.closeCustomDialog} aria-labelledby="customized-dialog-title" open={this.props.customDialog.show}>
            <DialogTitle id="customized-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {content}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.closeCustomDialog} color="primary">
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    }

    aboutDialog = () => {
        return <Dialog
            open={this.state.openAbout}
            onClose={this.handleCloseAbout}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div>Product Version: {this.state.clientVersion}</div>
                    <div>Backend Version: {this.state.backendVersion}</div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseAbout} color="primary" autoFocus>
                    {this.props.t("algemeen.Ok")}
                    </Button>
            </DialogActions>
        </Dialog>
    }

    public render() {
        return (
            <React.Fragment>
                {this.menu()}
                {this.languages()}
                {this.confirmationDialog()}
                {this.renderHeader()}
                {this.aboutDialog()}
                {this.customDialog(this.props.customDialog.title, this.props.customDialog.content, this.props.customDialog.buttonText)}
            </React.Fragment>
        );
    }
}

export default withTranslation()(withTheme<Theme, typeof HeaderComponent>(HeaderComponent));

