
import MomentUtils from '@date-io/moment';
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createGenerateClassName, StylesProvider } from '@material-ui/styles';
import axios from "axios";
import { ConnectedRouter } from "connected-react-router";
import * as moment from 'moment';
import "moment/locale/nl";
import "moment/locale/fr";
import React, { Suspense } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { AppContainer } from "react-hot-loader";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import 'typeface-roboto';
import configureAxios from './configureAxios';
import configureStore from './configureStore';
import './csp';
import { getTheme } from "./css/theme";
import i18n from "./i18n";
import './index.css';
import { preflightSession } from './modules/auth/authActions';
import { receiveConfig } from './modules/config/ConfigActions';
import * as RoutesModule from "./routes";
import { StatePersistance } from './statePersistance';
import { ApplicationState, historyObject } from './store';


const routes = RoutesModule.routes;
const generateClassName = createGenerateClassName();
const initialState: ApplicationState = StatePersistance.loadState("cmeal.state", {});
  
const store = configureStore(initialState);
store.subscribe(() => { //save state to localstorage
    StatePersistance.saveState("cmeal.state", store.getState());
});

configureAxios(store); 

async function loadTheme() {
    return await axios.get("/hstatic/theme.json", {
    }).then(response => {
        return getTheme(response.data);
    }).catch(error => {
        console.log(error);
    })
};

async function loadHospitalConfiguration()  {
    return await axios.get("/api/hospital/config")
        .then(async response => {   
            store.dispatch(receiveConfig(response.data));
            if (response.data.translationActive) {
                let found = false;
                if (i18n.language == undefined) {
                    i18n.changeLanguage(response.data.defaultLanguage);
                }
                else {
                    for (const key in response.data.languages) {
                        if (i18n.language == key) {
                            found = true;                           
                        }
                    }
                    if (!found) {
                        i18n.changeLanguage(response.data.defaultLanguage);
                    }
                }
            }
            else {                
                i18n.changeLanguage("nl");
            }
            return response.data;            
        }).catch(error => {
            //show error page
            console.log(error);
            initialState.config.appOffline = true;
        });
}

async function renderApp(theme: any) {
    return ReactDOM.render(
        <StylesProvider injectFirst generateClassName={generateClassName}>
            <React.Fragment>
                <CssBaseline />
                {
                    <Provider store={store}>
                        <I18nextProvider i18n={i18n}>
                            <Suspense fallback={() => {<div>loading</div>}}>
                                <MuiThemeProvider theme={theme}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <AppContainer>
                                            <ConnectedRouter history={historyObject} children={routes} />
                                        </AppContainer>
                                    </MuiPickersUtilsProvider>
                                    </MuiThemeProvider>
                                </Suspense>
                         </I18nextProvider>
                    </Provider>
                }
            </React.Fragment>
        </StylesProvider>,
        document.getElementById('root')
    )
}

async function main() {
    console.log('main begin');
    const theme = await loadTheme();
    console.log('theme loaded');
    const config = await loadHospitalConfiguration();    
    console.log('hospital configuration loaded');
    await preflightSession(store);    
    console.log('session preflight');

    await renderApp(theme);
    console.log('app rendered');

    store.dispatch(receiveConfig(config));
}

(async () => main())();

//main().then(() => {

//// If you want your app to work offline and load faster, you can change
//// unregister() to register() below. Note this comes with some pitfalls.
//// Learn more about service workers: https://bit.ly/CRA-PWA
//    //console.log('unregistered');
//    //serviceWorker.unregister();

//});