import { Paper, Theme, Typography, FormControlLabel, Checkbox, } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withTheme, WithTheme as WithThemeProps } from '@material-ui/styles';
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import i18n from 'i18next';
import styles from "./Profile.module.css";
import { ProfileAvatar } from "./ProfileAvatar";
import { Diet, Language, Profile } from "./ProfileTypes";

export interface ProfileProps {
    patientProfile: Profile;
    activeProfile: Profile;
    guestProfiles: Profile[];
    allDiets: Diet[];
    allLanguages: Language[];    
    profilePicture: string;
    isUsingAppTxt: string;
    featureChangeDiet: boolean;
    featureGuestEasters: boolean; 
    featureTranslationActive: boolean;
}

export interface ProfileComponentDispatchProps {
    getDiets: (profile: Profile) => void;
    getLanguages: () => void;
    saveProfile: (patientNumber: string, isDisclaimerChecked: boolean, preferredDietNumber: number, preferredLanguageNumber: number, isUsingApp: boolean, newCulture: string, profile: Profile) => void;

}
interface SettingsListBoxItemProps {
    label: string;
}
class SettingsListBoxItemComponent extends React.Component<SettingsListBoxItemProps, any>{

    render() {
        return <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography align='left'>{this.props.label}</Typography>
            <div style={{ marginLeft: '2em' }}>
                {this.props.children}
            </div>
        </ListItem>
    }
}

export class ProfileComponent extends React.Component<ProfileProps & ProfileComponentDispatchProps & WithThemeProps<Theme> & WithTranslation, any> {
    
    componentDidMount() {
        this.props.getDiets(this.props.activeProfile);
        this.props.getLanguages();
    }
    
    public render() {

        return <div>

            <div style={{ margin: '2em' }}>
                <Typography style={{ textAlign: 'left', fontWeight: 600 }}>{i18n.t("profiel.Patiënt").toUpperCase()}</Typography>
                {this.renderPatient(this.props.patientProfile)}

                {this.props.guestProfiles && this.props.guestProfiles.length > 0 &&
                    <Typography style={{ textAlign: 'left', marginTop: '2em', fontWeight: 600 }}>{this.props.t("profiel.Gasten")}</Typography>
                }
                {this.props.guestProfiles.map(guest => this.renderGuest(guest))}
            </div>

        </div>
    }

    private renderPatient(profile: Profile) {
        return this.renderProfile(profile)
    }

    private renderGuest(profile: Profile) {
        return <div key={profile.id} style={{ marginBottom: 25 }}>
            {this.renderProfile(profile)}
        </div>
    }

    private renderProfile(profile: Profile) {

        let isUsingApp;
        let languagePreference;
        let dietPreference;

        // TODO : translation label 'IsUsingApp -> {i18n.t("profiel.UseApp")} ! Dit werkt bij testen in development MAAR werkt NIET na build en release op Azure !!
        // dan staat er gewoon 'profiel.UseApp' ?! Werkt ook niet met this.props.t("profiel.UseApp")
        // misschien omdat het de laatste lijn is in de translation.json in de sectie 'profiel' ?
        // misschien omwile van checkbox?
        // <Typography> + <Ckeckbox> werkt wel maar dan is de tekst gecentreerd in het midden (zie checkbox van 'disclaimer')
        //this.props.isUsingAppTxt

        if (profile.isPrimary) {
            isUsingApp = <SettingsListBoxItemComponent label={i18n.t("profiel.UseApp")}>
                <Checkbox
                    disabled={!profile.disclaimerAccepted}
                    checked={profile.isUsingApp}
                    color="primary"
                    onChange={(e) => this.handleIsUsingAppChange(e, profile)}
                />
            </SettingsListBoxItemComponent>;
        }      

        const activeDiet = <SettingsListBoxItemComponent label={this.props.t("profiel.ToeDieet")}>
            <Typography align='left'><strong>{profile.actualDietNames.find(x => x.culture.toLowerCase().trim() == i18n.language.toLowerCase().trim()).name}</strong></Typography>
        </SettingsListBoxItemComponent>;

        //31/08/2022 : Hide preferredLanguage in Profile
        //if (this.props.featureTranslationActive && profile.isPrimary && this.props.allLanguages) {
        //    languagePreference = <SettingsListBoxItemComponent label={this.props.t("profiel.PrefLanguage")}>
        //        <FormControl>
        //            <Select disabled={!profile.disclaimerAccepted} style={{ minWidth: '10vw', maxWidth: '50vw' }} value={profile.preferredLanguage ? profile.preferredLanguage : ''}
        //                onChange={(e) => this.handleLanguageChange(e, profile)}>
        //                {
        //                    this.props.allLanguages.map((value, index) =>
        //                        <MenuItem className={styles.languageItem} key={index} value={value.id}>{value.name}</MenuItem>
        //                    )
        //                }
        //            </Select>
        //        </FormControl>
        //    </SettingsListBoxItemComponent>
        //}

        //08/02/2021 : ... Not (!profile.isPrimary && this.props.featureChangeDiet && this.props.allDiets && this.props.patientProfile.disclaimerAccepted) || (profile.isPrimary && ..
        //Same for Primary patient as for guests
        if (profile.newPatientAdaptDiet && this.props.featureChangeDiet && this.props.allDiets && this.props.patientProfile.disclaimerAccepted) {
            dietPreference = <SettingsListBoxItemComponent label={ this.props.t("profiel.VoorDieet")}>
                <FormControl>
                    <Select style={{ minWidth: '10vw', maxWidth: '50vw' }} value={profile.preferredDiet ? profile.preferredDiet : ''}
                        onChange={(e) => this.handleDietChange(e, profile)}>
                        {
                            this.props.allDiets.map((value, index) =>
                                <MenuItem className={styles.dietItem} key={index} value={value.id}>{value.name.toLowerCase()}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </SettingsListBoxItemComponent>
        }

        return <Paper square elevation={1}>
            <List>
                <ListItem key={profile.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div style={{ display: 'flex' }}>
                        <ListItemAvatar>
                            <ProfileAvatar profile={profile} />
                        </ListItemAvatar>
                        <ListItemText primary={profile.name} style={{ alignSelf: 'center' }} />
                    </div>
                </ListItem>
                <div style={{ marginLeft: '2em', marginRight: '1em' }}>
                    {isUsingApp}
                    {activeDiet}
                    {languagePreference}
                    {dietPreference}
                </div>

            </List>
        </Paper>
    }

    handleDietChange = (event: React.ChangeEvent<{ value: unknown }>, profile: Profile) => {

        const diet = event.target.value as number;
        this.props.saveProfile(profile.id, true, diet, profile.preferredLanguage, !profile.isUsingApp, profile.preferredLanguageCulture, this.props.activeProfile);
    }

    handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>, profile: Profile) => {

        const language = event.target.value as number;
        
        if (language != profile.preferredLanguage) {

            const languageCulture = this.props.allLanguages.find(x => x.id == language).culture.toLowerCase().trim();

            //for (var i = 0; i < this.props.guestProfiles.length; i++) {
            //    this.props.guestProfiles[i].preferredLanguage = language;
            //    this.props.guestProfiles[i].preferredLanguageCulture = languageCulture;
            //}

            this.props.activeProfile.preferredLanguage = language;
            this.props.activeProfile.preferredLanguageCulture = languageCulture;

            this.props.saveProfile(profile.id, true, profile.preferredDiet, language, !profile.isUsingApp, languageCulture, this.props.activeProfile);
            i18n.changeLanguage(languageCulture);
        }        
    }

    handleIsUsingAppChange = (event: React.ChangeEvent<HTMLInputElement>, profile: Profile) => {
        const isUsingApp = event.target.checked as boolean;
        
        this.props.saveProfile(profile.id, true, profile.preferredDiet, profile.preferredLanguage, !isUsingApp, profile.preferredLanguageCulture, this.props.activeProfile);
    }
}

export default withTranslation()(withTheme<Theme, typeof ProfileComponent>(ProfileComponent));
