import { FoodItem, FoodCourseGroup, FoodCourse, MealDay, ItemPortion, ExtraItem } from "../order/OrderTypes";

export const initialMenuState: MenuState = {
    
    menu: {
        menuKey: "",
        dailyMenu: {},
        courseGroups: {},
        courseGroupKeys: [],
        courses: {},
        courseKeys: [],
        foodItems: {},
        foodItemKeys: []
    },
    mealDays: null, 
    mealDayIndex: -1,
    loading: false,
    snackbar: {
        show: false,
        text: "",
        variant: "normal",
        duration: 10000,
    },
}

export interface Menu {
    dailyMenu: { [key: number]: ItemPortion };
    foodItems: { [key: number]: FoodItem };
    foodItemKeys: number[];
    courseGroups: { [key: number]: FoodCourseGroup };
    courseGroupKeys: number[];
    courses: { [key: number]: FoodCourse };
    courseKeys: number[];
    menuKey: string;
}

export interface MenuState {
    menu : Menu,
    mealDays: MealDay[],
    mealDayIndex: number,
    loading: boolean 
    snackbar: SnackbarStatus,
}

export interface SnackbarStatus {
    show: boolean,
    text: string,
    variant: string,
    duration: number,
}