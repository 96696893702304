import { connect } from "react-redux";
import { OrderState } from "../OrderTypes";
import { confirmMenu, setOverviewPanel } from "../OrderActions";
import OverviewMenuComponent, { OverviewMenuComponentProps, OverviewMenuComponentDispatchProps } from "./OverviewMenuComponent";
import { MenuState } from "../../menu/MenuTypes";


//const getCourseGroup = (state: MenuState, item: FoodItem) => { //puts the corresponding coursegroup in the provided fooditem
//    let courseGroups: FoodCourseGroup[] = state.courseGroups;
//    if (!courseGroups) {
//        return item;
//    }
//
//    for (let group of courseGroups) {
//        if (group.foodItems.find(x => x === item.id)) {
//            item.category = group.title;
//            return item;
//        }
//    }
//    return item;
//}

//const convertOrderToFoodItems = (myOrderState: OrderState, myMenuState: MenuState) => {
//    let itemPortions = myOrderState.itemPortions;
//    let foodItems = myMenuState.foodItems;
//    let myFoodItems: FoodItem[] = [];
//
//    for (let item of foodItems) { //map foodItemIDs that are in the order with the actual foodItem objects
//        let foundItem = itemPortions.find(x => x.foodItemID === item.id);
//        if (foundItem) {
//            item.portion = foundItem.portion;
//            myFoodItems.push(getCourseGroup(myMenuState, item));
//        }
//    }
//
//    return myFoodItems;
//}
const mapStateToMealMomentProps = (state: any): OverviewMenuComponentProps => {
    const myOrderState = state.order as OrderState;
    const menu = state.menu as MenuState;
    const itemPortions = myOrderState.itemPortions;
    const extraItems = myOrderState.foodItemsExtra;
    //const myFoodItems: number[] = [];
    const myExtraFoodItems: number[] = [];

    let sortedItemPortions = Object.keys(itemPortions).map(k => parseInt(k));
    sortedItemPortions = sortedItemPortions.sort((key1, key2) => itemPortions[key1].menuChartLineNr - itemPortions[key2].menuChartLineNr);

    const myFoodItems: number[] = Array.from(sortedItemPortions.values());

    for (const keyEIp in extraItems) {
        myExtraFoodItems.push(extraItems[keyEIp].id);
    }

    if (myFoodItems.length > 1 && myFoodItems[0] == 0) {
        myFoodItems.shift();
    }
     
    return {
        foodItems: myFoodItems,
        portions: myOrderState.itemPortions,
        foodItemsExtra: myExtraFoodItems,
        mealMoment: myOrderState.mealMoment,
        overviewPanelOpen: myOrderState.overviewPanelOpen,
    };
};

const mapDispatchToProps = (dispatch: any): OverviewMenuComponentDispatchProps => {
    return {
        confirmMenu: () => {
            dispatch(confirmMenu(false, false));
        },
        setOverviewPanel: (open: boolean) => {
            dispatch(setOverviewPanel(open));
        }
    };
};

export const OverviewMenuContainer = connect(
    mapStateToMealMomentProps, mapDispatchToProps)(OverviewMenuComponent) as React.ComponentClass;
