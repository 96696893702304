import { menuAction } from "./MenuActions";
import { MenuState, initialMenuState, Menu } from "./MenuTypes";
import { FoodItem, FoodCourseGroup, FoodCourse, MealDay, MealMoment, PatientMealMoments } from "../order/OrderTypes";
import { orderAction } from "../order/OrderActions";

export const menuReducer = (state: MenuState = initialMenuState, action: any): MenuState => {
    switch (action.type) {
        case menuAction.REQUEST_MENU:
            return handleRequestMenu(state);
        case menuAction.ERROR_RECEIVE_MENU:
            return handleErrorReceiveMenu(state);
        case menuAction.SELECT_MEAL_DAY:
            return handleSelectMealDay(state, action.payload as number);
        case menuAction.RECEIVE_MEAL_DAYS:
            return handleReceiveMealDays(state, action.payload);
        case menuAction.RECEIVE_MEAL_MOMENT:
            return handleReceiveMealMoment(state, action.payload);
        case menuAction.CONFIRM_NOMEALS:
            return handleConfirmNoMeals(state, action.payload.mealDate);
        case menuAction.RECEIVE_MENU:
            return handleReceiveMenu(state, action.payload);
        case menuAction.CLEAR_MENU:
            return handleClearMenu(state);
        case menuAction.SHOW_SNACKBAR:
            return handleShowSnackbar(state, action.payload);
        case menuAction.CLOSE_SNACKBAR:
            return handleCloseSnackbar(state);        
        default:
            return state;
    }
}

const handleErrorReceiveMenu = (state: MenuState) => {
    return {
        ...state,
        loading: false,
    }
}

const handleRequestMenu = (state: MenuState) => {
    return {
        ...initialMenuState,
        mealDays: state.mealDays,
        mealDayIndex : state.mealDayIndex,
        loading: true

    }
}

const handleReceiveMenu = (state: MenuState, payload: Menu): MenuState => {
    return {
        ...state,
        loading: false,
        menu: payload
    }
}

const handleReceiveMealDays = (state: MenuState, payload: PatientMealMoments): MenuState => {
    let index: number = state.mealDayIndex;
    if (index == - 1) {
        index = payload.firstAvailableMealDayIndex;
    }
    return {
        ...state,
        mealDays: payload.mealDays,
        mealDayIndex: index
    }
}

const handleReceiveMealMoment = (state: MenuState, moment: MealMoment): MenuState => {

    const currentMealDays = state.mealDays;

    let newMealDays = currentMealDays.map((day) => {
        let isDay = moment.date == day.mealDate; 
        if (isDay) {
            let newDay: MealDay = {
                ...day,
                mealMoments: day.mealMoments.map((currentMoment) => {
                    const matchMoment = currentMoment.id == moment.id; 
                    if (matchMoment) {
                        return moment;
                    }
                    return currentMoment;
                })
            }
            return newDay;
        }
        return day;
    });

    return {
        ...state,
        mealDays: newMealDays
    }
}

const handleConfirmNoMeals = (state: MenuState, mealDate: string): MenuState => {

    console.log('handleConfirmNoMeals');
    const currentMealDays = state.mealDays;
    const incomingDay = mealDate;

    console.log('incoming: ' + incomingDay);

    let newMealDays = currentMealDays.map((day) => {
        let isDay = (incomingDay == day.mealDate); 
        if (isDay) {
            console.log('Cancel meals for ' + incomingDay);
            let newDay: MealDay = {
                ...day,
                mealMoments: day.mealMoments.map((currentMoment) => { 
                    return {
                        ...currentMoment,
                        isOrdered: currentMoment.blocked ? currentMoment.isOrdered : true,
                        isNoMeal: currentMoment.blocked ? currentMoment.isNoMeal : true
                    };
                })
            }
            return newDay;
        }
        return day;
    });

    return {
        ...state,
        mealDays: newMealDays
    }

}

    const handleClearMenu = (state: MenuState) => {
    return {
        ...initialMenuState,
        mealDayIndex: state.mealDayIndex
    }
}

const handleSelectMealDay = (state: MenuState, payload: number) => {
    return {
        ...state,
        mealDayIndex: payload
    }
}
 
const handleShowSnackbar = (state: MenuState, payload: any) => {
    if (!payload.text) {
        return {
            ...state
        };
    }
    return {
        ...state,
        snackbar: {
            show: true,
            text: payload.text,
            variant: payload.variant,
            duration: 3000
        }
    }
}

const handleCloseSnackbar = (state: MenuState) => {
    //sleep(10000);
    return {
        ...state,
        snackbar: {
            ...state.snackbar,
            show: false,
            text: '',
        }
    }
}

//function sleep(time: number) {
//    return new Promise((resolve) => setTimeout(resolve, time));
//}