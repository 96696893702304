import { connect } from "react-redux";
import { MenuState } from "../../menu/MenuTypes";
import { OrderState } from "../OrderTypes";
import MealMomentComponent, { MealMomentProps, MealMomentComponentDispatchProps } from "./MealMomentComponent";
import { getMealDays, selectMealDay, confirmNoMeals } from "../../menu/MenuActions";
import { selectMealMoment } from "../OrderActions";
import { MealMoment, MealDay } from "../OrderTypes";
import { ProfileState, Profile } from "../../profile/ProfileTypes"; 

const mapStateToMealMomentProps = (state: any): MealMomentProps => {
    let myMenuState = state.menu as MenuState;
    let myOrderState = state.order as OrderState;
    let myProfileState = state.profile as ProfileState;
    let activeProfile: Profile = myProfileState.profiles.find(pr => pr.id == myProfileState.activeProfileID);

    return {
        mealDays: myMenuState ? myMenuState.mealDays : null,
        selectedMealMoment: myOrderState ? myOrderState.mealMoment : null,
        mealDayIndex: myOrderState ? myMenuState.mealDayIndex : 0,
        profile: activeProfile,
        patient: myProfileState.patient,
        guests: myProfileState.guests,
        hasMultipleProfiles: myProfileState.profiles.length > 1
    };
};

const mapDispatchToProps = (dispatch: any): MealMomentComponentDispatchProps => {
    return {
        selectMealMoment: (mealMoment: MealMoment) => {
            dispatch(selectMealMoment(mealMoment));
        },
        selectMealDay: (index: number) => {
            dispatch(selectMealDay(index));
        },
        selectProfile: (profile: Profile) => {
            dispatch(getMealDays(profile));
        },
        confirmNoMeals: (day: MealDay, profile: Profile) => { 
            dispatch(confirmNoMeals(day));
        },
    };
};

export const MealMomentContainer = connect(
    mapStateToMealMomentProps, mapDispatchToProps)(MealMomentComponent) as React.ComponentClass;
