import * as React from "react";
import { connect } from "react-redux";
import { MenuState } from "../../menu/MenuTypes"; 
import { FoodCourseProps, FoodCourseDispatchProps, FoodCourseComponent } from "./FoodCourseComponent";
import { goNextStep, selectMenu } from "../OrderActions";
import { OrderState,MenuType } from "../OrderTypes";
import { discriminateFoodCourse } from "../discrimination/discriminationSelectors";
import { withTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
 
const mapStateToProps = (state: any, ownProps: FoodCourseProps): FoodCourseProps => {
    let myMenuState = state.menu as MenuState;
    let myOrderState = state.order as OrderState;
    let myCourse = myMenuState.menu.courses[ownProps.id];
    let courseGroupIDs = myCourse.courseGroups;

    let captionResult = discriminateFoodCourse(ownProps.id, state);

    return {
        ...ownProps,
        title: myCourse.title,
        isExceeded: myOrderState.mealMoment.isBBF && !myOrderState.mealMoment.isBBFSelectable ? false : captionResult.isExceeded,
        courseGroupIDs: courseGroupIDs,
        expanded: myOrderState.expandedCoursePanel === 0 ? Object.keys(myMenuState.menu.courses)[0] === ownProps.id.toString() : myOrderState.expandedCoursePanel === ownProps.id,
        //Open first panel if nothing else is opened.
    };
}

const mapDispatchToProps = (dispatch: any, ownProps: FoodCourseProps): FoodCourseDispatchProps => {
    return {
        selectCoursePanel: (event: any, expanded: boolean) => { 
            dispatch(selectMenu(MenuType.CUSTOM_MENU, ownProps.id, null));
        },
        nextStep: (id: number) => {
            dispatch(goNextStep(id));
        }
    };
}

export const FoodCourseContainer = connect(
    mapStateToProps, mapDispatchToProps)(withTheme<Theme, typeof FoodCourseComponent>(FoodCourseComponent)) as React.ComponentClass<FoodCourseProps>;
