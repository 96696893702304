import * as React from 'react';
import { WithTheme as WithThemeProps, withTheme } from '@material-ui/styles';
import { Typography, Theme, Paper, FormControl, FormControlLabel, Checkbox, FormHelperText, Button } from '@material-ui/core';
import { withTranslation, WithTranslation } from "react-i18next";

export interface DisclaimerProps {
    content: string;
    isAccepted: boolean;
    canChangeDiet: boolean;
}

export interface DisclaimerDispatchProps {
    approve: () => void;
}

class DisclaimerComponent extends React.Component<DisclaimerProps & DisclaimerDispatchProps & WithTranslation, any> {

    public render() {
        return <div style={{ margin: '2em' }}>
            <Typography style={{ textAlign: 'left', fontWeight: 600 }}>DISCLAIMER</Typography>
            <Paper square elevation={1} style={{padding: '2em'}}>
                <Typography style={{ textAlign: 'left'}}>{this.props.content}</Typography>
                <FormControl required>
                    <FormControlLabel control={
                        <Checkbox checked={this.props.isAccepted}
                            disabled={this.props.isAccepted}
                            color="primary"
                            onChange={(e, checked) => this.approveDisclaimer(checked)}
                        />}
                        label={this.props.t("profiel.Disclaimer")} />
                    {!this.props.isAccepted && this.props.canChangeDiet &&
                        <FormHelperText>{this.props.t("profiel.DisclaimerError")}</FormHelperText>
                    }
                </FormControl>

            </Paper>

        </div>
    }

    private approveDisclaimer(checked: boolean) {
        console.log('approveDisclaimer component')
        this.props.approve();
    }

}

export default withTranslation()(withTheme<Theme, typeof DisclaimerComponent>(DisclaimerComponent));