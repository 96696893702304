import * as React from "react";
import { connect } from "react-redux";
import { closeSnackbar } from "../order/OrderActions";
import ErrorSnackbarComponent, { ErrorProps, ErrorSnackbarComponentDispatchProps } from "./ErrorSnackbarComponent"; 
import  { OrderState } from "../order/OrderTypes";


const mapStateToProps = (state: any): ErrorProps => {
    let myOrderState = state.order as OrderState;
    return {
        snackbar: myOrderState.snackbar,
    };
};

const mapDispatchToProps = (dispatch: any): ErrorSnackbarComponentDispatchProps => {
    return {
        closeSnackbar: () => {
            dispatch(closeSnackbar());
        },
    };
};
 
export const ErrorSnackbarContainer = connect(
    mapStateToProps, mapDispatchToProps)(ErrorSnackbarComponent) as React.ComponentClass;