import { connect } from "react-redux";
import DisclaimerComponent, { DisclaimerProps, DisclaimerDispatchProps } from "./DisclaimerComponent";
import { ProfileState, Profile } from "./ProfileTypes";
import { approveDisclaimer } from "./ProfileActions";
import { ConfigState } from "../config/ConfigTypes";
import i18n from 'i18next';

const mapStateToProps = (state: any): DisclaimerProps => {
    const profile = state.profile as ProfileState;
    const myConfigState = state.config as ConfigState;
    const patient = profile.patient;
    
    return {
        content: !patient.disclaimers.find(x => x.culture.toLowerCase().trim() == i18n.language.toLowerCase().trim()).disclaimer ? i18n.t("profiel.Disclaimer1") + " " + i18n.t("profiel.Disclaimer2") : patient.disclaimers.find(x => x.culture.toLowerCase().trim() == i18n.language.toLowerCase().trim()).disclaimer,
        isAccepted: patient.disclaimerAccepted,
        canChangeDiet: myConfigState.changeDiet
    };
}

const mapDispatchToProps = (dispatch: any): DisclaimerDispatchProps => {
    return {
        approve: () => {
            dispatch(approveDisclaimer()); 
        }
    };
}

export const DisclaimerContainer = connect(mapStateToProps, mapDispatchToProps)(DisclaimerComponent) as React.ComponentClass;