import * as React from "react";
import { Paper, Typography } from "@material-ui/core";
import styles from "./ErrorComponent.module.css";
import { useTranslation } from 'react-i18next';

export const ErrorComponent = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.errorPage}>
            <Paper>
                <img src={process.env.PUBLIC_URL + '404_not_found.jpg'} alt="404 not found" />
            </Paper>

            <Typography variant="body1">
                {t("error.Error")};
            </Typography>
        </div>
    )
}