import { connect } from "react-redux";
import { ConfigState } from "../config/ConfigTypes";
import { getDiets, getLanguages, saveProfile } from "./ProfileActions";
import ProfileComponent, { ProfileComponentDispatchProps, ProfileProps } from "./ProfileComponent";
import { Profile, ProfileState } from "./ProfileTypes";


const mapStateToProps = (state: any): ProfileProps => {
    let myProfileState = state.profile as ProfileState;
    let myConfigState = state.config as ConfigState;
 
    let activeProfile: Profile;
    let otherProfiles: Profile[] = [];

    for (let profile of myProfileState.profiles) {
        if (profile.id == myProfileState.activeProfileID)
            activeProfile = profile;
        else
            otherProfiles.push(profile);
    }

    return {
        patientProfile: myProfileState.patient,
        activeProfile: activeProfile,
        guestProfiles: myProfileState.guests,
        allDiets: myProfileState ? myProfileState.diets : null,
        allLanguages: myProfileState ? myProfileState.languages : null,
        profilePicture: activeProfile ? activeProfile.profilePicture : null,
        isUsingAppTxt: activeProfile ? activeProfile.isUsingAppTxt : null,
        featureChangeDiet: myConfigState.changeDiet,
        featureGuestEasters: myConfigState.guestEaters,
        featureTranslationActive: myConfigState.translationActive
    };
};

const mapDispatchToProps = (dispatch: any): ProfileComponentDispatchProps => {
    return {
        saveProfile: (patientNumber: string, isDisclaimerChecked: boolean, preferredDietNumber: number, preferredLanguage: number, isUsingApp: boolean, newCulture: string, profile: Profile) => dispatch(saveProfile(patientNumber, isDisclaimerChecked, preferredDietNumber, preferredLanguage, isUsingApp, newCulture, profile)),
        getDiets: (profile: Profile) => {
            dispatch(getDiets(profile));           
        },
        getLanguages: () => {
            dispatch(getLanguages());
        }        
    };
};

export const ProfileContainer = connect(
    mapStateToProps, mapDispatchToProps)(ProfileComponent) as React.ComponentClass;
