import * as React from "react";
import TodayIcon from "@material-ui/icons/Today"
import Typography from "@material-ui/core/Typography";
import styles from "./OrderMenu.module.css";
import { DateTimeLabel, DateTimeLabelFormat } from "../../dateTimeLabel";
import { WithTheme as WithThemeProps, withTheme } from '@material-ui/styles';
import { Theme } from "@material-ui/core";

export interface InformationHeaderProps {
    title: string,
    date: Date,
}

class InformationHeaderComponent extends React.Component<InformationHeaderProps & WithThemeProps<Theme>, {}> {
    public render() {
        const headerColor = {
            backgroundColor: this.props.theme.informationHeader.main,
        };

        const renderInformationHeader = () => {
            return <div style={headerColor} className={styles.header}>
                <TodayIcon id={styles.headerContent} />
                <span>
                    <Typography display="inline" variant="subtitle2" id={styles.headerContent}>
                    {this.props.title} /
                       </Typography>
                    <Typography display="inline" variant="body2" id={styles.headerContent}>
                        <DateTimeLabel value={this.props.date} type={DateTimeLabelFormat.FullDate} />
                    </Typography>
                </span>

            </div>
        }

        return renderInformationHeader();
    }
}

export default withTheme<Theme, typeof InformationHeaderComponent>(InformationHeaderComponent);
