import * as React from "react";
import SelectProfileComponent from "./../profile/SelectProfileComponent";
import { Redirect } from "react-router";
import { Profile } from "./../profile/ProfileTypes";
import { WithTranslation } from 'react-i18next';
import i18n from 'i18next';

export interface HomeProps {
    hasMultipleProfiles: boolean;
    patient: Profile,
    guests: Profile[]
}

export interface HomeDispatchProps {
    selectProfile: (profile: Profile) => void;
}

export class HomeComponent extends React.Component<HomeProps & HomeDispatchProps & WithTranslation, any> {

    state = {
        redirectOnAction: false
    };

    public componentDidMount() { 
        i18n.changeLanguage(this.props.patient.preferredLanguageCulture);

        this.props.selectProfile(this.props.patient);
        //16/12/2022 : Always fill the selectedProfile because if the popup appears with multiple profile one can click
        // on the 'hamburger-menu' and select 'Profile' -> if you then click on the 'back' arrow the page won't load because
        // the selectedProfile is empty !!
        //if (!this.props.hasMultipleProfiles) {
        //    this.props.selectProfile(this.props.patient);
        //}        

        this.setState({
            redirectOnAction: false
        });        
    }

    selectProfile(profile: Profile) {
        console.log('onselectprofile');
        this.props.selectProfile(profile);
        i18n.changeLanguage(profile.preferredLanguageCulture);
        this.setState({
            redirectOnAction: true
        });
    }

    public render() {
        if (!this.props.hasMultipleProfiles || this.state.redirectOnAction) {        
            return <Redirect to='/menu' />
        } else {
            return <div style={{ margin: '25px' }}>
                <h3>{this.props.t("home.Wie")}</h3>                
                <SelectProfileComponent
                    patient={this.props.patient}
                    guests={this.props.guests}
                    onSelectProfile={this.selectProfile.bind(this)}/>
            </div>
        }
    }
} 
