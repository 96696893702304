import { initialProfileState, ProfileState, Diet, Language, Profile } from "./ProfileTypes";
import { AuthenticationResult } from "./../auth/authTypes";
import { profileAction } from "./ProfileActions";
import { PatientMealMoments } from "../order/OrderTypes";
import { actionsEnum as authActions } from "./../auth/authActions";
import { menuAction } from "./../menu/MenuActions";

export const profileReducer = (state: ProfileState = initialProfileState, action: any): ProfileState => {
    switch (action.type) {
        case profileAction.RECEIVE_DIETS:
            return handleReceiveDiets(state, action.payload);
        case profileAction.RECEIVE_LANGUAGES:
            return handleReceiveLanguages(state, action.payload);
        case profileAction.RECEIVE_PROFILE:
            return handleProfileUpdated(state, action.payload);
        case profileAction.SET_ACTIVE_PROFILE:
            return handleSetActiveProfile(state, action.payload);
        case authActions.RECEIVE_USER:
            return handleReceiveUser(state, action.payload);
        case menuAction.RECEIVE_MEAL_DAYS:
            return handleReceiveMealDays(state, action.payload);
        case "@@router/LOCATION_CHANGE":
            return handleRouteChange(state, action.payload);
        case profileAction.SHOW_SNACKBAR:
            return handleShowSnackbar(state, action.payload);
        case profileAction.CLOSE_SNACKBAR:
            return handleCloseSnackbar(state);
        default:
            return state;
    }
}

const handleReceiveDiets = (state: ProfileState, payload: Diet[]): ProfileState => {
    return {
        ...state,
        diets: payload
    }
}

const handleReceiveLanguages = (state: ProfileState, payload: Language[]): ProfileState  => {
    return {
        ...state,
        languages: payload
    }
}

const handleSetActiveProfile = (state: ProfileState, payload: Profile): ProfileState => {
    return {
        ...state,
        activeProfileID: payload.id
    }
}

const handleReceiveMealDays = (state: ProfileState, payload: PatientMealMoments): ProfileState => {
    return {
        ...state,
        activeProfileID: payload.pid
    }
}

const handleProfileUpdated = (state: ProfileState, payload: Profile): ProfileState => {
    let updatedStateProfileIndex = state.profiles.findIndex(x => x.id == payload.id);
    state.profiles[updatedStateProfileIndex] = payload;
    if (payload.isPrimary) {
        return {
            ...state,
            patient: payload
        }
    } else {
        const guests = state.guests;
        const updatedGuests = guests.map((guest, index) => {
            if (guest.id !== payload.id) {
                return guest
            }
            return payload
        })
        return {
            ...state,
            guests: updatedGuests
        }
    }
}

const handleReceiveUser = (state: ProfileState, payload: AuthenticationResult): ProfileState => {
    const profiles = payload.profiles;
    const patient = profiles.find(p => p.isPrimary);
    const guests = profiles.filter(p => !p.isPrimary);
    return {
        ...state,
        profiles: profiles,
        patient,
        guests, 
        activeProfileID: patient.id,
        isDisclaimerFirstTimeDisplayed: !patient.disclaimerAccepted
    }
}

const handleRouteChange = (state: ProfileState, payload: any): ProfileState => {

    let isDisclaimerFirstTimeDisplayed = state.isDisclaimerFirstTimeDisplayed;

    if (isDisclaimerFirstTimeDisplayed &&
        payload &&
        payload.action === "PUSH" &&
        payload.location.pathname === "/profile" &&
        payload.isFirstRendering === false) {
        isDisclaimerFirstTimeDisplayed = false;
    }

    return {
        ...state,
        isDisclaimerFirstTimeDisplayed
    }
}

const handleShowSnackbar = (state: ProfileState, payload: any): ProfileState => {
    if (!payload.text) {
        return {
            ...state
        };
    }
    return {
        ...state,
        snackbar: {
            show: true,
            text: payload.text,
            variant: payload.variant,
            duration: payload.duration,
            textAlign: payload.textAlign
        }
    }
}

const handleCloseSnackbar = (state: ProfileState) => {
    return {
        ...state,
        snackbar: {
            ...state.snackbar,
            show: false,
            text: '',
        }
    }
}


