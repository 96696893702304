import * as React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import styles from "./LoginComponent.module.css";
import { ApplicationState } from "../../store";
import { ConfirmLogonExpired } from '../auth/authActions';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface ExpiredComponentDispatchProps {
    login: () => void;
}

export class ExpiredComponent extends React.Component<ExpiredComponentDispatchProps & WithTranslation> {
 
    public render() {

        let title = <Typography variant="h5" gutterBottom>
            {this.props.t("expired.Expired")}            
                   </Typography>
         
        let loginButton = <Button className={styles.loginButton} variant="contained" size="large" color="primary"
            onClick={() => this.props.login()}>
            {this.props.t("expired.Login")}   
                   </Button>
         
        return <div className={styles.loginContainer}>
            {title}
            {loginButton} 
        </div >;
    }     
}

const mapStateToProps = (state: ApplicationState): any => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any, ): ExpiredComponentDispatchProps => {
    return {
        login: () => {
            dispatch(ConfirmLogonExpired()); 
        }
    };
};

export const ExpiredContainer = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExpiredComponent)) as React.ComponentClass<any>;

