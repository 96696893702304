import * as React from "react";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./CustomMenu.module.css";
import { FoodCourseGroupContainer } from "./FoodCourseGroupContainer";
import Avatar from "@material-ui/core/Avatar";
import { Theme } from "@material-ui/core";
import i18n from 'i18next';

export interface FoodCourseProps {
    id: number,
    title?: string,
    isExceeded: boolean,
    courseGroupIDs?: number[],
    expanded: boolean,
    theme?: Theme,
}

export interface FoodCourseDispatchProps {
    selectCoursePanel(event: any, expanded: boolean): void;
    nextStep(id: number): void;
}

export class FoodCourseComponent extends React.Component<FoodCourseProps & FoodCourseDispatchProps, {}> {

    public render() {
        const primaryColor = this.props.theme.palette.primary.main;
        const avatarStyle = {
            backgroundColor: primaryColor,
        };

        const numberIcon = () => {
            return <Avatar style={avatarStyle} className={styles.circleNumber}>
                       {this.props.id + 1}
                   </Avatar>
        }
        const renderFoodCourse = () => {
            return <ExpansionPanel expanded={this.props.expanded} onChange={this.props.selectCoursePanel}>
                <ExpansionPanelSummary className={this.props.expanded ? styles.panelHeader : null} expandIcon={<ExpandMoreIcon />}>
                    <Typography className={styles.courseTitle} color={this.props.isExceeded ? "error" : "initial"} variant="h6">{this.props.title.toLowerCase()}</Typography>
                       </ExpansionPanelSummary>
                       <ExpansionPanelDetails>
                           <div className={styles.foodContainer}>
                               {
                            this.props.courseGroupIDs.map((id, i) =>
                                <FoodCourseGroupContainer courseID={this.props.id} courseGroupID={id} key={i} >

                                </FoodCourseGroupContainer>
                            )
                        }
                        <Button id={styles.button} variant="contained" color="secondary" onClick={() => this.props.nextStep(this.props.id)}>{i18n.t("algemeen.Volgende")}</Button>
                           </div>
                       </ExpansionPanelDetails>
                   </ExpansionPanel>
        }

        return renderFoodCourse();
    }
}