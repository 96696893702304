import { Button, Dialog, Divider, Typography } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import i18n from 'i18next';
import * as React from "react";
import { Prompt, Redirect } from "react-router";
import InformationHeaderComponent from "./InformationHeaderComponent";
import styles from "./OrderMenu.module.css";
import { FoodItem, FoodItemDialogStatus, ItemPortion, ExtraItem, MealMoment, MenuType } from "./OrderTypes";
import { TabMenuComponent } from "./TabMenuComponent";

export interface OrderProps {
    mealMoment: MealMoment;
    menuType: MenuType;
    foodItemDialog: FoodItemDialogStatus;
    featureMealPictures: boolean;
    featureIsDietOptionVisible: boolean;
    isDirty: boolean;
    foodItems: { [key: number]: FoodItem };
    foodItemsPortions: { [key: number]: ItemPortion };
    foodItemsExtra: { [key: number]: ExtraItem };
}

export interface OrderComponentDispatchProps {
    changeMenu: (event: any, tabIndex: any) => void;
    unloadOrder: () => void;
    closeDialog: () => void;
}

export class OrderComponent extends React.Component<OrderProps & OrderComponentDispatchProps, any> {
    getStripedStyle(index: number) {
        return { background: index % 2 ? 'white' : 'whitesmoke' };
    }

    FoodInformationDialogComponent = (data: FoodItemDialogStatus) => {

        const containedAllergens: string[] = [];
        const containedTracesAllergens: string[] = [];
        const noInfoOnAllergens: string[] = [];
        const wrongInfoOnAllergens: string[] = [];
        const nutrients100g: string[] = [];
        const nutrientsPortion: string[] = [];

        if (data.allergens) {
            data.allergens.forEach(allergen => {
                if (allergen.type == "+") {
                    containedAllergens.push(allergen.name);
                }
                else {
                    if (allergen.type == "#") {
                        containedTracesAllergens.push(allergen.name);
                    }
                    else {
                        if (allergen.type == "?") {
                            noInfoOnAllergens.push(allergen.name)
                        }
                        else {
                            if (allergen.type == "/") {
                                wrongInfoOnAllergens.push(allergen.name)
                            }
                        }
                    }
                }
            });
        }

        if (data.nutrients) {
            data.nutrients.forEach(nutrient => {
                nutrients100g.push(nutrient.name.trim() + ": " + nutrient.value.toFixed(2) + " " + nutrient.unit.substr(0, 2) + ".");
                nutrientsPortion.push(nutrient.name.trim() + ": " + nutrient.totCalculatedValuePerPortion.toFixed(2) + " " + nutrient.unit.substr(0, 2) + ".");
            });
        }

        //const Item = styled(Grid)(({ theme }) => ({
        //    ...theme.typography.body2,
        //    //,textAlign: 'left',
        //    //height: 'auto',
        //    //width: '100%',
        //    //lineHeight: '60px'
        //}));

        const renderNutrientTable = () => {
            return <Table size="small" style={{ borderColor: "lightgrey", borderStyle: "solid", borderWidth: "thin", borderCollapse: "unset" }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left" style={{ paddingTop: 0, paddingBottom: 0 }}><Typography style={{ fontWeight: 600 }} variant="body2" color="textSecondary" component="p">{i18n.t('allergeen.Omschrijving')}</Typography></TableCell>
                        <TableCell align="right" style={{ paddingTop: 0, paddingBottom: 0 }}><Typography style={{ fontWeight: 600 }} variant="body2" color="textSecondary" component="p">100g</Typography></TableCell>
                        <TableCell align="right" style={{ paddingTop: 0, paddingBottom: 0 }}><Typography style={{ fontWeight: 600 }} variant="body2" color="textSecondary" component="p">{i18n.t('algemeen.Portie')}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.nutrients.map((nutrient) => (
                        <TableRow>
                            <TableCell align="left" style={{ borderBottom: "none", paddingTop: 0, paddingBottom: 0, ...this.getStripedStyle(nutrient.sortOrder) }}>
                                <Typography variant="body2" color="textSecondary" component="p">{nutrient.name}</Typography>
                            </TableCell>
                            <TableCell align="right" style={{ borderBottom: "none", paddingTop: 0, paddingBottom: 0, ...this.getStripedStyle(nutrient.sortOrder) }}>
                                <Typography variant="body2" color="textSecondary" component="p">{nutrient.value.toFixed(2) + " " + nutrient.unit.substr(0, 2) + "."}</Typography>
                            </TableCell>
                            <TableCell align="right" style={{ borderBottom: "none", paddingTop: 0, paddingBottom: 0, ...this.getStripedStyle(nutrient.sortOrder) }}>
                                <Typography variant="body2" color="textSecondary" component="p">{nutrient.totCalculatedValuePerPortion.toFixed(2) + " " + nutrient.unit.substr(0, 2) + "."}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
        }


        //Classes style is used to override the default 48 px margin of material ui
        return <Dialog classes={{ paper: styles.dialog }} onClose={this.props.closeDialog} aria-labelledby="customized-dialog-title" open={this.props.foodItemDialog.show} >
            <Card style={{ overflow: "auto", height: "auto" }}>
                {data.url_image != "" && this.props.featureMealPictures ? <div className={styles.foodInformationImage}>
                    <img src={data.url_image}
                        onError={(e: any) => { e.target.onerror = null; e.target.style.display = "none" }} />
                </div> : null}
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {data.description}
                    </Typography>
                            {containedAllergens.length + containedTracesAllergens.length + noInfoOnAllergens.length + wrongInfoOnAllergens.length > 0 ?
                                <Typography className={styles.foodDialogTitle} variant="body2" color="textSecondary" component="p">
                                    <b>{i18n.t('allergeen.Titel')} </b>
                                </Typography> : null}

                                {containedAllergens && containedAllergens.length > 0 ? <Typography variant="body2" className={styles.nutrientDividerFullWidth} color="textSecondary" component="p">
                                <b> {i18n.t('allergeen.Bevat')} </b> {containedAllergens.join(', ').toLocaleLowerCase()}
                                </Typography> : null}
                                {containedTracesAllergens && containedTracesAllergens.length > 0 ? <Typography variant="body2" className={styles.nutrientDividerFullWidth} color="textSecondary" component="p">
                                <b> {i18n.t('allergeen.BevatSporen')} </b> {containedTracesAllergens.join(', ').toLocaleLowerCase()}
                                </Typography> : null}
                                {noInfoOnAllergens && noInfoOnAllergens.length > 0 ? <Typography variant="body2" className={styles.nutrientDividerFullWidth} color="textSecondary" component="p">
                                <b> {i18n.t('allergeen.GeenInfo')} </b> {noInfoOnAllergens.join(', ').toLocaleLowerCase()}
                                </Typography> : null}
                                {wrongInfoOnAllergens && wrongInfoOnAllergens.length > 0 ? <Typography variant="body2" className={styles.nutrientDividerFullWidth} color="textSecondary" component="p">
                                <b> {i18n.t('allergeen.GeenJuisteInfo')} </b> {wrongInfoOnAllergens.join(', ').toLocaleLowerCase()}
                                </Typography> : null}

                            {(containedAllergens.length + containedTracesAllergens.length + noInfoOnAllergens.length + wrongInfoOnAllergens.length > 0) && data.nutrients && data.nutrients.length > 0 ? <Divider style={{ paddingBottom: 2 }} /> : null}
                                {data.nutrients && data.nutrients.length > 0 ? <Typography variant="body2" className={styles.foodDialogTitle} style={{ paddingTop: 5 }} color="textSecondary" component="p">
                                <b>{i18n.t('nutrient.Titel')} </b>
                            </Typography> : null}
                            {/*Nutrients in Text-Format*/}
                            {/*{data.nutrients && data.nutrients.length > 0 ? <Typography className={styles.nutrientDividerFullWidth} variant="body2" color="textSecondary" component="p">*/}
                            {/*    <b style={{ fontStyle: "italic" }}>100g: </b>{nutrients100g.join(', ').toLocaleLowerCase()}</Typography> : null}*/}
                            {/*{data.nutrients && data.nutrients.length > 0 ? <Typography className={styles.nutrientDividerFullWidth} variant="body2" color="textSecondary" component="p">*/}
                            {/*    <b style={{ fontStyle: "italic" }}>{i18n.t('algemeen.Portie')}: </b> {nutrientsPortion.join(', ').toLocaleLowerCase()}</Typography> : null}*/}

                            {/*Nutrients in Table-Format*/}
                            {data.nutrients && data.nutrients.length > 0 ? renderNutrientTable() : null}

                            {data.detailedDescription && (containedAllergens.length + containedTracesAllergens.length + noInfoOnAllergens.length + wrongInfoOnAllergens.length > 0) && (!data.nutrients || data.nutrients.length <= 0) ? <Divider light style={{ marginTop: 2, marginBottom: 2 }} /> : null}
                            {data.detailedDescription ? <Typography variant="body2" className={styles.foodDialogTitle} style={{ paddingTop: 5 }} color="textSecondary" component="p">
                                <b> {i18n.t('allergeen.Omschrijving')}</b> </Typography> : null}
                            {data.detailedDescription ? <Typography variant="body2"className={styles.dividerFullWidth} style={{paddingLeft: 2 }} color="textSecondary" component="p">{data.detailedDescription.toLocaleLowerCase()}</Typography> : null}

                </CardContent>

                <CardActions disableSpacing>
                    <Button className={styles.foodDialogClose} size="small" color="primary" onClick={this.props.closeDialog}>
                        {i18n.t("algemeen.Sluit")}
                    </Button>
                </CardActions>
            </Card>
            </Dialog >        
    }

    public componentWillUnmount() {
        this.props.unloadOrder();
    }



    public render() {
        const message: string = i18n.t('order.DmenuOpm');

        if (this.props.mealMoment) {
            const mealMomentDate = new Date(this.props.mealMoment.date);
            const dietDescription = this.props.featureIsDietOptionVisible && this.props.mealMoment.diet.dietDescription;
            return <div>
                <Prompt
                    when={this.props.isDirty}
                    message={() =>
                        message
                    }
                />
                {this.FoodInformationDialogComponent(this.props.foodItemDialog)}
                <InformationHeaderComponent title={this.props.mealMoment.title} date={mealMomentDate} />
                <TabMenuComponent
                    firstTitle={this.props.mealMoment.isOrdered ? i18n.t('order.Ukeuze') : this.props.mealMoment.isBBFSelectable ? i18n.t('order.Ukeuze') : i18n.t('order.Mvd')} // || this.props.mealMoment.isNoMeal
                    secondTitle={this.props.mealMoment.isOrdered ? i18n.t('order.DmenuPa') : i18n.t('order.DmenuSs')}
                    isDirty={this.props.isDirty}
                    tabChanger={this.props.changeMenu}
                    activeTab={this.props.menuType}
                    information={this.props.mealMoment.description.toLowerCase()}
                    dietDescription={dietDescription}
                    mealMomentIsBlocked={this.props.mealMoment.blocked}
                    isBBF={this.props.mealMoment.isBBF}
                    isBBFReadonly={!this.props.mealMoment.isBBFSelectable}
                    foodItems={this.props.foodItems}
                    foodItemsPortions={this.props.foodItemsPortions}
                    foodItemsExtra={this.props.foodItemsExtra}
                />
            </div >
        } else {
            return <Redirect to="/" />
        }

    }
}

