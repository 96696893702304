import { Theme } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { withTheme, WithTheme as WithThemeProps } from '@material-ui/styles';
import i18n from 'i18next';
import * as React from "react";
import { DateTimeLabel, DateTimeLabelFormat } from "../../../dateTimeLabel";
import { FoodItemExtraContainer } from "../dailyMenu/FoodItemExtraContainer";
import { FoodItemContainer } from "../FoodItemContainer";
import { ItemPortion, MealMoment } from "../OrderTypes";
import styles from "./OverviewMenu.module.css";

export interface OverviewMenuComponentProps {
    mealMoment: MealMoment;
    foodItems: number[];
    portions: { [key: number]: ItemPortion };
    foodItemsExtra: number[];
    overviewPanelOpen: boolean;
}

export interface OverviewMenuComponentDispatchProps {
    confirmMenu: () => void;
    setOverviewPanel: (open: boolean) => void;
}

export class OverviewMenuComponent extends React.Component<OverviewMenuComponentProps & OverviewMenuComponentDispatchProps & WithThemeProps<Theme>, {}> {

    state = {
        openInfoDialog: false,
    };

    handleClickOpen = () => {
        this.props.setOverviewPanel(true);
    };

    handleClose = () => {
        this.props.setOverviewPanel(false);
    };

    confirmMenu = () => {
        this.props.confirmMenu();
    }

    appBarColor = {
        backgroundColor: this.props.theme.overviewHeader.main,
    };

    public render() {

        if (this.props.foodItems[0] != 0) {
            return <div>
                <Button className={styles.largeButton} variant="contained" color="primary" onClick={this.handleClickOpen} disabled={this.props.mealMoment.isBBF && !this.props.mealMoment.isBBFSelectable}>
                    {i18n.t('overViewMenu.Bevestig')}
                   </Button>
                <Dialog className={styles.dialog}
                    fullScreen
                    open={this.props.overviewPanelOpen}
                    onClose={this.handleClose}>
                    <AppBar style={this.appBarColor}>
                        <Toolbar className={styles.toolBar}>
                            <Typography variant="h6">
                                {i18n.t('overViewMenu.Bevestig')}                                
                               </Typography>
                            <IconButton onClick={this.handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={styles.dialogContainer}>
                        <Typography variant="subtitle1">
                            {this.props.mealMoment.title} voor < DateTimeLabel value={this.props.mealMoment.date} type={DateTimeLabelFormat.FullDate} />
                        </Typography>
                        {this.props.foodItems.length > 0 && <br />}
                        <List>
                            {
                                this.props.foodItems.map((id: number, index: number) => {
                                    let showDivider = this.props.foodItems.length !== index + 1;
                                    let portion = this.props.portions[id];
                                    let amount = 0;
                                    if (portion) {
                                        amount = portion.portion;
                                    }
                                    return <FoodItemContainer id={id} key={index} showDivider={showDivider} amount={amount} mealMomentIsBlocked={ false} />
                                })
                            }
                        </List>

                        {this.props.foodItemsExtra.length > 0 && <br />}
                        {this.props.foodItemsExtra.length > 0 &&
                            <Typography variant="subtitle1">
                            {i18n.t('overViewMenu.Extra') } {this.props.mealMoment.title}
                            </Typography>
                        }
                        {this.props.foodItemsExtra.length > 0 &&
                            <List>
                                {
                                    this.props.foodItemsExtra.map((food: number, i: number) =>
                                        <FoodItemExtraContainer id={food} key={i} divider={true} />
                                    )
                                }
                            </List>
                        }
                        <div className={styles.buttonContainer}>
                            <Button className={styles.confirmButton} variant="contained" color="primary" onClick={this.confirmMenu}>
                                {i18n.t('overViewMenu.BestelM')}
                        </Button>
                            <Button className={styles.confirmButton} variant="contained" color="secondary" onClick={this.handleClose}>
                                {i18n.t('overViewMenu.AanpassenM')}
                        </Button>
                        </div>

                    </div>
                </Dialog>
            </div>

        }
        else {
            return <div>
                <Button className={styles.largeButton} variant="contained" color="primary" onClick={this.handleClickOpen} disabled={this.props.mealMoment.isBBF && !this.props.mealMoment.isBBFSelectable}>
                    {i18n.t('overViewMenu.Bevestig')}
                   </Button>
                <Dialog className={styles.dialog}
                    fullScreen
                    open={this.props.overviewPanelOpen}
                    onClose={this.handleClose}>
                    <AppBar className={styles.appBar}>
                        <Toolbar className={styles.toolBar}>
                            <Typography variant="h6">
                                {i18n.t('overViewMenu.Bevestig')}
                               </Typography>
                            <IconButton onClick={this.handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className={styles.dialogContainer}>
                        <Typography variant="subtitle1">
                            {this.props.mealMoment.title} voor <DateTimeLabel value={this.props.mealMoment.date} type={DateTimeLabelFormat.FullDate} />
                        </Typography>
                        <List id={styles.list}>
                            {
                                <Typography variant="subtitle1" color="error" >{i18n.t('overViewMenu.GeenM')}</Typography>
                            }
                        </List>
                        <div className={styles.buttonContainer}>
                            <Button className={styles.confirmButton} variant="contained" color="secondary" onClick={this.handleClose}>
                                {i18n.t('overViewMenu.AanpassenM')}
                            </Button>
                        </div>

                    </div>
                </Dialog>
            </div>
        }

    }
}

export default withTheme<Theme, typeof OverviewMenuComponent>(OverviewMenuComponent);
