import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { LogonExpired } from './authActions';

function getCookieValue(cname: string): string {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    const ca_length = ca.length;
    for (let i: number = 0; i < ca_length; i += 1) {
        let c: string = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) !== -1) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function getSessionCookieValue() {
    return getCookieValue("CMeal.SID");
}


class ClientSessionProviderState {
    client_session_monitor_handle: number;
    lastCookie: string;
}

class ClientSessionProviderProps {
    isExpired: boolean;
    isLoggingOut: boolean;
    sid: string; 
}

class ClientSessionProviderDispatchProps {
    expireLogon: () => void;
}

class ClientSessionProviderComponent extends React.Component<ClientSessionProviderProps & ClientSessionProviderDispatchProps, ClientSessionProviderState> {

    constructor(props: any) {
        super(props);
        this.state = {
            client_session_monitor_handle: undefined,
            lastCookie: undefined
        };
    }

    componentDidMount() {
        if (!this.props.isExpired) {
            this.startMonitoring();
        }       
    }

    componentWillUnmount() {
        this.stopMonitoring();
    }

    render(): any {
        return <div>{this.props.children}</div>;
    }

    startMonitoring(): void {
        if (!this.state.client_session_monitor_handle) {
            const handle = window.setInterval(() => this.checkCookie(), 100);
            (window as any).client_session_monitor_handle = handle;
            this.setState({
                ...this.state,
                client_session_monitor_handle: handle
            });
            console.log('session monitor started');
        }
    }

    stopMonitoring(): void {

        const handle = (window as any).client_session_monitor_handle;
        if (handle) {
            window.clearInterval(handle);
            this.setState({
                lastCookie: undefined,
                client_session_monitor_handle: undefined
            });
            console.log('session monitor stopped');
        }
    }

    checkCookie(): void {

        const currentCookie = document.cookie;
        const lastCookie = this.state.lastCookie;
        if (currentCookie != lastCookie) {
            // something useful like parse cookie, run a callback fn, etc.

            const sid = getSessionCookieValue();
            const localsid = this.props.sid;

            if (sid != localsid) {
                //non-matching session
                //client logout
                this.stopMonitoring();
                if (!this.props.isLoggingOut) {
                    this.props.expireLogon();
                }
            }

            this.setState({
                ...this.state,
                lastCookie: lastCookie
            });
        }

    }

}

const mapStateToProps = (state: ApplicationState): ClientSessionProviderProps => {
    return {
        isExpired: state.auth.isExpired,
        sid: state.auth.sid,
        isLoggingOut: state.auth.isLoggingOut
    }
}

const mapDispatchToProps = (dispatch: any): ClientSessionProviderDispatchProps => {
    return {
        expireLogon: () => {
            dispatch(LogonExpired());
        }
    };
};

export const ClientSessionProvider = connect(mapStateToProps, mapDispatchToProps)(ClientSessionProviderComponent) as React.ComponentClass;
