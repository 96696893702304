import { Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withTheme, WithTheme as WithThemeProps } from '@material-ui/styles';
import * as React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { DateTimeLabel, DateTimeLabelFormat } from "../../../dateTimeLabel";
import { Profile } from "../../profile/ProfileTypes";
import SelectProfileComponent from "../../profile/SelectProfileComponent";
import { MealDay, MealMoment } from "../OrderTypes";
import styles from "./MealMoment.module.css";
import MealMomentButtonComponent from "./MealMomentButtonComponent";


export interface MealMomentProps {
    mealDays: MealDay[],
    selectedMealMoment: MealMoment,
    mealDayIndex: number,
    profile: Profile,
    patient: Profile,
    guests: Profile[]
    hasMultipleProfiles: boolean
}

export interface MealMomentComponentDispatchProps {
    selectMealMoment: (mealMoment: MealMoment) => void;
    selectMealDay: (index: number) => void;
    selectProfile: (profile: Profile) => void; 
    confirmNoMeals: (day: MealDay, profile: Profile) => void;
}

class MealMomentComponent extends React.Component<MealMomentProps & MealMomentComponentDispatchProps & WithThemeProps<Theme> & WithTranslation, any> {

    constructor(props: any) {
        super(props);
    }
     
    state = {
        openNoMeals: false,
        openSelectProfile: false
    };

    handleChange = (event: any, tabIndex: any) => {
        this.props.selectMealDay(tabIndex);
    };

    handleClickOpenNoMeals = () => {
        this.setState({ openNoMeals: true });
    };

    handleClickOpenSelectProfile = () => {
        this.setState({ openSelectProfile: true });
    };

    handleClose = () => {
        this.setState({ openNoMeals: false, openSelectProfile: false });
    };

    handleConfirmNoMeals = () => {
        this.props.confirmNoMeals((this.props.mealDays[this.props.mealDayIndex]), this.props.profile);
        this.handleClose();
    }

    selectProfile = (profile: Profile) => {       
        this.props.selectProfile(profile);
        this.handleClose();
    }

    public render() {
        let allMealsBlocked = false;
        const cancelColors = this.props.theme.cancelComponents;
        const cancelButtonColor = {
            backgroundColor: cancelColors.main,
            color: cancelColors.contrastText
        };
         
        const FoodTab = (day: MealDay, index: any) => {
            let selectedDay = this.props.mealDayIndex == index ? day : null;
            if (selectedDay != null) {
                allMealsBlocked = selectedDay.mealMoments.findIndex(mm => mm.blocked == false) < 0 || selectedDay.mealMoments.findIndex(mm => mm.toggle == true) < 0 ?
                    true : false;
                selectedDay = null;
            }            
            const title = <Typography style={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap", fontWeight: "bold" }}>
                {this.props.profile && this.props.profile.name ? this.props.profile.name : this.props.t('meal.Mmaaltijd')}
            </Typography>

            let content;

            if (!this.props.hasMultipleProfiles) {
                content = title;
            } else {
                content = <Button onClick={this.handleClickOpenSelectProfile}>
                    {title}
                    <ArrowDropDownIcon />
                </Button>
            }


            return <div className={styles.tab} hidden={this.props.mealDayIndex !== index} key={index}>
                <div style={{ marginTop: 5 }}>                    
                    {content}
                </div> 

                <div style={{display: 'inline-grid'}}>
                    {day.mealMoments.map((mealMoment: MealMoment, i) =>
                        <MealMomentButtonComponent selectMealMoment={this.props.selectMealMoment} data={mealMoment} key={i} logo={i} />)
                    }
                </div>
                
            </div>
        }
         
        function TabLabel(dateString: string) {
            const dateValue = new Date(dateString);
            return <div className={styles.tabLabel}>
                <Typography variant="subtitle2">
                    <strong>{
                        <DateTimeLabel value={dateValue} type={DateTimeLabelFormat.Day} />
                    }</strong>
                </Typography>
                <DateTimeLabel value={dateValue} type={DateTimeLabelFormat.Date} />
            </div>;
        }

        const tabsMenu = () => {
            return <div>
                <Paper elevation={1} square={true} className={styles.paper}>
                    <Tabs
                        value={this.props.mealDayIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant={this.props.mealDays.length > 3 ? "scrollable" : "fullWidth"}
                        scrollButtons={this.props.mealDays.length > 3 ? "on" : "off"}
                        {...this.props.mealDays.length <= 3 ? "centered" : ""}
                    >
                        {
                            this.props.mealDays.map((mealday) =>
                                <Tab label={TabLabel(mealday.mealMoments[0].date)} key={mealday.mealDate} />
                            )
                        }
                    </Tabs>
                </Paper>

                {
                    this.props.mealDays.map((value, index) => {
                        return FoodTab(this.props.mealDays[index], index);
                    })
                }

                <Button id={styles.confirmButton} onClick={this.handleClickOpenNoMeals} variant="contained" size="large" style={allMealsBlocked ? {} : cancelButtonColor} disabled={allMealsBlocked}>
                    {this.props.t('meal.MgeenMlt')}
                </Button>

                {selectProfileDialog()}
                {confirmationNoMealsDialog()}

            </div>;
        }
 
        const selectProfileDialog = () => {
            return <Dialog
                open={this.state.openSelectProfile}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Selecteer profiel"}</DialogTitle>
                <DialogContent id={styles.dialogNoPadding}>
                    <SelectProfileComponent
                        patient={this.props.patient}
                        guests={this.props.guests}
                        onSelectProfile={(profile)=> this.selectProfile(profile)}/>

                </DialogContent>
            </Dialog>
        }

        const confirmationNoMealsDialog = () => {
            return <Dialog
                open={this.state.openNoMeals}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{this.props.t("order.DmenuGeen")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.props.t('order.Dzeker')}
                           </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleConfirmNoMeals} color="primary" autoFocus>
                        {this.props.t("algemeen.Bevestigen")}
                    </Button>
                    <Button onClick={this.handleClose} color="primary">
                        {this.props.t("algemeen.Annuleer")}
                    </Button>
                </DialogActions>
            </Dialog>
        }

        if (!this.props.mealDays) {
            return <CircularProgress className={styles.loadingSpinner}/>;           
        }

        return <div>
            {tabsMenu()}
        </div>;
    }
}

export default withTranslation()(withTheme<Theme, typeof MealMomentComponent>(MealMomentComponent));
